import { del, get, post, put } from "../client";
import {
  ADD_BUSINESS,
  BUSINESSES_LIST,
  CANCEL_APPOINTMENT,
  CONFIRM_APPOINTMENT,
  DELETE_ACCOUNT,
  GET_PROFILE,
  SCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT_WITH_PAYMENT,
  UPDATE_IMAGE,
  USER_APPOINTMENTS,
} from "../routes";

export default {
  profile: async () => await get(GET_PROFILE),

  update_image: async (data) => await put(UPDATE_IMAGE, data),

  delete_account: async () => await del(DELETE_ACCOUNT),

  schedule_appointment: async (data) => await post(SCHEDULE_APPOINTMENT, data),

  schedule_appointment_with_payment: async (data) => await post(SCHEDULE_APPOINTMENT_WITH_PAYMENT, data),

  cancel_appointment: async (data) => await put(CANCEL_APPOINTMENT, data),

  appointments: async () => await get(USER_APPOINTMENTS),

  confirm_appointment: async (data) => await put(CONFIRM_APPOINTMENT, data),

  view_appointment: async (data) => await get(CONFIRM_APPOINTMENT, data),

  get_businesses_list: async () => await get(BUSINESSES_LIST),

  add_business: async (data) => await post(ADD_BUSINESS, data),
};
