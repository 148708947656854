import { post, put } from "../client";
import { LOGIN, UPDATE_PROFILE, VERIFY } from "../routes";

export default {
  login: async (data) => await post(LOGIN, data, false),

  verify: async (data) => await post(VERIFY, data, false),

  update_profile: async (data) => await put(UPDATE_PROFILE, data),
};
