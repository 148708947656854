import { Employees } from "./Employees";
import { Days } from "./Days";
import styles from "../../styles/scheduleSection.module.scss";

const Schedule = ({
  currentEmployee,
  setCurrentEmployee,
  employees,
  setDoesBusinessHasSchedule,
}) => {
  let flow = localStorage.getItem("flow");
  flow = JSON.parse(flow);

  return (
    <div className={styles.container}>
      <h2>שעות פעילות</h2>

      {/* show only if there is more than one employee */}
      {employees?.length > 1 && (
        <p className={styles.mainText}>לעסק זה קיימים מספר עובדים</p>
      )}

      <div className={styles.card}>
        {/* === EMPLOYEES === */}
        {flow === 1 && (
          <Employees
            styles={styles}
            data={employees}
            currentEmployee={currentEmployee}
            setCurrentEmployee={setCurrentEmployee}
          />
        )}

        {/* === SCHEDULE === */}
        <Days
          styles={styles}
          currentEmployee={currentEmployee}
          employees={employees}
          setDoesBusinessHasSchedule={setDoesBusinessHasSchedule}
        />
      </div>
    </div>
  );
};

export default Schedule;
