import { useContext } from "react";
import { ThemeContext } from "../../App";
import { Caution } from "../../svgs/caution";
import Modal from "../../components/Modal";
import styles from "../../styles/confirmModal.module.scss";

export const ConfirmModal = ({ setIsOpen, handleClick }) => {
  const color = useContext(ThemeContext);

  return (
    <Modal>
      <div className={styles.container}>
        <Caution fill={color.mainColor} />
        <h2>מחיקת משתמש</h2>
        <p>
          שימו לב שבעת מחיקת הפרופיל, כל התורים, העסקים אליהם נרשמת והתוכן ימחקו
          לצמיתות.
        </p>
        <div className={styles.btns}>
          <button
            onClick={handleClick}
            style={{ backgroundColor: color.mainColor }}
          >
            מחק משתמש
          </button>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              border: `1px solid ${color.mainColor}`,
              color: color.mainColor,
              background: color.subColor,
            }}
          >
            ביטול
          </button>
        </div>
      </div>
    </Modal>
  );
};
