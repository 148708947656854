import styles from "../styles/modal.module.scss";

const Modal = ({ children, setIsOpen, onClick }) => {
  return (
    <div className={styles.mainContainer} onClick={onClick}>
      <div className={styles.backdrop} onClick={() => setIsOpen && setIsOpen(false)}></div>
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
