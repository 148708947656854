export const Caution = ({ fill }) => {
  return (
    <svg
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50.5"
        cy="50.5"
        r="49.5"
        fill={fill}
        fill-opacity="0.15"
        stroke={fill}
        stroke-width="2"
      />
      <path
        d="M46.744 60.624L44.92 30.096L44.536 18H57.688L57.208 30.096L55.384 60.624H46.744ZM51.064 83.472C48.824 83.472 46.904 82.672 45.304 81.072C43.768 79.408 43 77.392 43 75.024C43 72.592 43.768 70.576 45.304 68.976C46.904 67.376 48.824 66.576 51.064 66.576C53.432 66.576 55.352 67.376 56.824 68.976C58.36 70.576 59.128 72.592 59.128 75.024C59.128 77.392 58.36 79.408 56.824 81.072C55.352 82.672 53.432 83.472 51.064 83.472Z"
        fill={fill}
      />
    </svg>
  );
};
