import styles from "../styles/appStoreBar.module.scss";

const AppStoreBar = ({ setIsOpen }) => {
  return (
    <div className={styles.appStoreBar}>
      <p className={styles.close} onClick={() => setIsOpen(false)}>
        &times;
      </p>

      <p className={styles.text}>התחברו דרך האפליקציה &gt;&gt;</p>

      <div className={styles.btns}>
        <a
          id="appclick"
          href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
          target="_blank"
        >
          <img src="/assets/app-store.svg" alt="app-store" />
        </a>
        <a
          id="gclick"
          href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
          target="_blank"
        >
          <img src="/assets/google-play.svg" alt="google-playstore" />
        </a>
      </div>
    </div>
  );
};

export default AppStoreBar;
