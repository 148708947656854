import { IMAGE_BASE_URL } from "../config";

// export const generateProfileImgUrl = (img) => {
//   if (!img) {
//     return "/assets/placeholder-profile.png";
//   } else if (img.includes("business_profile/")) {
//     return `${IMAGE_BASE_URL}/v1/uploads/${img}`;
//   } else {
//     return img;
//   }
// };

export const generateProfileImgUrl = (img) => {
  if (!img) {
    return "/assets/placeholder-profile.png";
  } else if (img.includes("data:image") || img.includes("https://api.tor.digital/")) {
    return img;
  } else {
    return IMAGE_BASE_URL + "/v1/uploads/" + img;
  }
};

export const ProfileImage = ({ img, name }) => {
  const hasImage =
    img && (img.includes("business_profile/") || img.includes("http"));
  const imgSrc = hasImage
    ? img.includes("business_profile/")
      ? `${IMAGE_BASE_URL}/v1/uploads/${img}`
      : img
    : "/assets/placeholder-profile.png";

  return hasImage ? (
    <img src={imgSrc} alt={name} style={{ borderRadius: "9px" }} />
  ) : (
    <div
      style={{
        background: "#DE1468",
        color: "white",
        borderRadius: "9px",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {name.charAt(0).toUpperCase()}
    </div>
  );
};
