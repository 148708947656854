import styles from "../styles/downloadApp.module.scss";

const DownloadApp = ({ type }) => {
  return (
    <div className={styles.store}>
      {type === 1 ? (
        <h4 className={styles.headingOne}>שווה להוריד את האפליקציה שלנו!</h4>
      ) : (
        <h4 className={styles.headingTwo}>
          על מנת לעקוב אחרי התורים שלך
          <br />
          יש להוריד את האפליקציה שלנו בחנויות האפליקציות
        </h4>
      )}
      {type === 1 && (
        <p>
          לקביעת תור מהיר וסנכרון עם היומן שלך
          <br />
          מומלץ להוריד את האפליקציה שלנו ולהתחבר עם הטלפון
        </p>
      )}
      <div className={styles.btns}>
        <a
          id="appclick"
          href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
          target="_blank"
        >
          <img src="/assets/app-store-dark.svg" alt="app-store" />
        </a>
        <a
          id="gclick"
          href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
          target="_blank"
        >
          <img
            src="/assets/google-play-dark.svg"
            alt="google-playstore"
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
