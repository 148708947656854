import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../App";
import axios from "axios";
import DownloadApp from "../../components/downloadApp";
import USER from "../../api/services/USER";
import Loader from "../../components/Loader";
import BUSINESS from "../../api/services/BUSINESS";
import { BASE_URL } from "../../config";
import { hebrewMonth } from "../../utils/getHebrewMonth";
import { hebrewDay } from "../../utils/getHebrewDay";
import { format } from "date-fns";
import DetailsModal from "./DetailsModal";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";
import { ConfirmModal } from "./ConfirmModal";
import { ConfirmModal2 } from "./ConfirmModal2";
import styles from "../../styles/myAppointmentsCard.module.scss";

const Card = () => {
  let color = useContext(ThemeContext);

  const [appointments, setAppointments] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [showModal, setShowModal] = useState(false);

  // FETCH USER APPOINTMENTS
  useEffect(() => {
    setDeleteSuccess(null);
    const fetchData = async () => {
      try {
        const res = await USER.appointments();

        if (res.data.length > 0) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          let filteredArr = res.data.filter((appt) => {
            const apptDate = new Date(appt.date);
            apptDate.setHours(0, 0, 0, 0);
            return apptDate >= today;
          });
          let sortArr = filteredArr.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
          setAppointments(sortArr);
        } else {
          setAppointments([]);
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    fetchData();
  }, [deleteSuccess]);

  if (!appointments) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  // CANCEL THE APPOINTMENT
  const cancel = async () => {
    const payload = {
      id: isConfirmModalOpen,
      status: 3,
    };
    try {
      const res = await USER.cancel_appointment(payload);
      setDeleteSuccess(true);
      setIsConfirmModalOpen(false);
      setIsOpen(false);
      toast.success("התור בוטל בהצלחה");
    } catch (error) {
      console.log(error);
      setIsConfirmModalOpen(false);
      setIsOpen(false);
      toast.error(error.response.data.message);
    }
  };

  // HANDLE CONFIRMATION
  const handleConfirmation = async (id, newStatus) => {
    try {
      const response = await axios.put(`${BASE_URL}/appointment/confirm/`, {
        appointmentId: id,
        isConfirmed: newStatus,
      });
      setShowModal(false);
      window.location.reload(); // Refresh the page
    } catch (err) {
      console.log("Error confirming appointment: " + err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.head} style={{ backgroundColor: color.mainColor }}>
        <h2>התורים שלי</h2>
        <p>תשלח הודעת תזכורת לנייד</p>
      </div>
      <div className={styles.body}>
        <div className={styles.store}>
          <DownloadApp type={1} />
        </div>
        <hr />
        <div className={styles.appointments}>
          {appointments.length > 0 ? (
            appointments.map((i) => (
              <div key={i._id}>
                <Appointment
                  key={i._id}
                  data={i}
                  serviceID={i.serviceId}
                  date={i.date}
                  slot={i.timeSlot}
                  setSelectedItem={setSelectedItem}
                  setIsOpen={setIsOpen}
                  color={color}
                  setShowModal={setShowModal}
                />
                <hr />
              </div>
            ))
          ) : (
            <p className={styles.noAppointment}>עדיין לא קבעת תור</p>
          )}
        </div>
      </div>

      {isOpen && selectedItem && (
        <DetailsModal
          setIsOpen={setIsOpen}
          data={selectedItem}
          setConfirm={setIsConfirmModalOpen}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal setIsOpen={setIsConfirmModalOpen} handleClick={cancel} />
      )}

      {showModal && (
        <ConfirmModal2
          setIsOpen={setShowModal}
          handleClick={() => handleConfirmation(showModal, "DECLINED")}
        />
      )}
    </div>
  );
};

export default Card;

const Appointment = ({
  data,
  serviceID,
  date,
  slot,
  setSelectedItem,
  setIsOpen,
  color,
  setShowModal,
  handleConfirmation,
}) => {
  let businessCancellationPeriod = localStorage.getItem(
    "business_cancellation_period"
  );

  const [canCancelled, setCanCancelled] = useState(true);

  // check if user can cancel appointment
  useEffect(() => {
    // appointment date
    let appointmentDate = new Date(data.date);

    appointmentDate = appointmentDate.setHours(0, 0, 0);

    // Add timeslots to the start date (hours)
    appointmentDate = new Date(appointmentDate).setHours(
      new Date(appointmentDate).getHours() + data.timeSlot.slice(0, 2)
    );

    // Add timeslots to the start date (minutes)
    appointmentDate = new Date(appointmentDate).setMinutes(
      new Date(appointmentDate).getMinutes() +
        data.timeSlot.substring(data.timeSlot.length - 2)
    );

    // current date in minutes
    let currDateInMins = Math.floor(new Date().getTime() / 1000 / 60);

    // appointment date in minutes
    let appointmentDateInMins = Math.floor(
      new Date(appointmentDate).getTime() / 1000 / 60
    );

    // minutes left in the scheduled appointment
    let minutesLeft = appointmentDateInMins - currDateInMins;

    // if the remaining minutes are greater than the cancellation minutes then cancel the appointment
    if (minutesLeft < JSON.parse(businessCancellationPeriod)) {
      setCanCancelled(false);
    } else {
      setCanCancelled(true);
    }
  }, []);

  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");

  // fetch service details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await BUSINESS.service_details(serviceID);
        setServiceName(res.data.name);
        setServicePrice(res.data.price);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    fetchData();
  }, []);

  if (!serviceName) {
    return (
      <div className={styles.appointment}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  const buttonStyles = {
    border: `1px solid ${color.mainColor}`,
    color: color.mainColor,
  };

  return (
    <div className={styles.appointment}>
      <div className={styles.top}>
        <div className={styles.text}>
          <h5>{serviceName}</h5>
          <p>
            {hebrewDay(date)} {format(new Date(date), "dd")} ל
            {hebrewMonth(date)} בשעה {slot}
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedItem({
              data,
              service_name: serviceName,
              service_price: servicePrice,
            });
            setIsOpen(true);
          }}
          style={buttonStyles}
        >
          פרטים
        </button>
      </div>
      <div className={styles.bottom}>
        {data?.isConfirmed === "CONFIRMED" ? (
          <div className={`${styles.approved} ${styles.status}`}>
            <p>אישרת הגעה</p>
          </div>
        ) : data?.isConfirmed === "DECLINED" ? (
          <div className={`${styles.declined} ${styles.status}`}>
            <p>לא אישרת הגעה</p>
          </div>
        ) : (
          <div className={styles.action}>
            {/* <p>אנא אשרו הגעה לתור</p>
            <div className={styles.btns}>
              {new Date(date) > new Date() && (
                <button
                  className={styles.approveBtn}
                  onClick={() => handleConfirmation(data._id, "CONFIRMED")}
                >
                  מגיע לתור שלי
                </button>
              )}
              {canCancelled && (
                <button
                  className={styles.declineBtn}
                  onClick={() => {
                    setShowModal(data._id);
                  }}
                >
                  לא מגיע לתור שלי
                </button>
              )}
            </div> */}
          </div>
        )}
      </div>

      {!canCancelled && (
        <p style={{ textAlign: "center", color: "red" }}>
          זמן ביטול התור עבר. יש לפנות לבית העסק
        </p>
      )}
    </div>
  );
};
