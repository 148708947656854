import { useContext, useState } from "react";
import { ThemeContext } from "../../App";

export const Employees = ({
  styles,
  data,
  currentEmployee,
  setCurrentEmployee,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  let color = useContext(ThemeContext)

  // IF THERE IS ONLY ONE EMPLOYEE THEN DON'T RENDER
  if (data?.length === 1) {
    return null;
  }

  return (
    <div className={styles.employees}>
      {<p>בחרו עובד לצפייה בשעות הפעילות</p>}
      <div className={styles.tabs}>
        {data?.map((i) => (
          <div
            className={styles.tab}

            style={
              {
                border: currentEmployee === i.id ? `1px solid ${color.mainColor}` : isHovered === i.id ? `1px solid ${color.mainColor}` : ""
              }
            }
            key={i.id}
            onClick={() => setCurrentEmployee(i.id)}
            onMouseEnter={() => setIsHovered(i.id)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <p style={
              {
                color: currentEmployee === i.id ? color.mainColor : isHovered === i.id ? color.mainColor : ""
              }
            }>{i.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
