import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/header.module.scss";
import { ThemeContext } from "../App";
import { useContext } from "react";

const Header = ({ setIsOpen }) => {
  const navigate = useNavigate();
  let color = useContext(ThemeContext)
  // GET BUSINESS CODE FROM THE LOCAL STORAGE
  let businessCode = localStorage.getItem("business_code");
  businessCode = JSON.parse(businessCode);
  let token = localStorage.getItem("token");

  return (
    <header  className={styles.header}>
      <div className={styles.wrapper}>
        <img
          src="/assets/hamburger.svg"
          alt="hamburger"
          className={styles.hamburger}
          onClick={() => setIsOpen(true)}
        />
        <img src="/assets/white-logo.svg" alt="logo" className={styles.logo} onClick={() => navigate(paths.business(businessCode))} />
        {!token && (
        <button onClick={() => navigate(paths.login)}>התחברות/הרשמה</button>
        )}
      </div>
    </header>
  );
};

export default Header;
