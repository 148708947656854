import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/sideMenu.module.scss";
import { useEffect, useState } from "react";
import USER from "../api/services/USER";
import { IMAGE_BASE_URL } from "../config";

const SideMenu = ({ setIsOpen }) => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState(null)
  const [userImg, setUserImg] = useState(null)

  let token = localStorage.getItem("token");
  let businessCode = localStorage.getItem("business_code");
  businessCode = JSON.parse(businessCode);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate(paths.login);
  };

  const style = {
    height: "100vh",
    width: "100%",
    position: "fixed",
    left: "0px",
    top: "0px",
    opacity: 0.45,
    background: "#000000 0% 0% no-repeat padding-box",
  };

  const Backdrop = ({ setIsOpen }) => (
    <div style={style} onClick={() => setIsOpen(false)}></div>
  );

  // FETCH USER DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await USER.profile();
        setUserName(res.user.name)
        setUserImg(res.user.profile)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  const DEFAULT_IMAGE_URL =
    "https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg";

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <aside className={`${styles.sideMenu}`}>
        <span onClick={() => setIsOpen(false)}>&times;</span>

        {/* USER */}
        <div className={styles.user}>
          {userImg && <img
            src={
              userImg
                ? IMAGE_BASE_URL + "/v1/uploads/" + userImg
                : DEFAULT_IMAGE_URL
            }
            alt={userName}
          />}
          {userName && <p>{userName}</p>}
        </div>

        <hr />

        {/* NAV ITEMS */}
        <nav>
         
          <ul>
            <a href={paths.business(businessCode)}>
              <li>ראשי</li>
            </a>
            {token && (
            <a href={paths.profile}>
              <li>הפרופיל שלי</li>
            </a>
             )}
             {token && (
            <a href={paths.my_appointments}>
              <li>התורים שלי</li>
            </a>
            )}
            <a href="https://join.tor.digital">
              <li>בעל עסק? לחץ כאן להרשמה מהירה</li>
            </a>
            {token && (
              <li className={styles.logout} onClick={handleLogout}>
                <img src="/assets/logout.svg" alt="logout" />
                התנתקות
              </li>
            )}
          </ul>
        </nav>

        <hr />

        {/* STORE BUTTONS */}
        <div className={styles.btns}>
          <h4>הורידו את האפליקציה לקביעת תור מהיר וסנכרון ליומן</h4>
          <div className={styles.row}>
            <a
              id="appclick"
              href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
              target="_blank"
            >
              <img src="/assets/app-store-dark.svg" alt="app-store" />
            </a>
            <a
              id="gclick"
              href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
              target="_blank"
            >
              <img
                src="/assets/google-play-dark.svg"
                alt="google-playstore"
              />
            </a>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SideMenu;
