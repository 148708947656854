import styles from "../../styles/invalidBusiness.module.scss";

export const InvalidBusiness = () => {
  // check what device does the user have
  const isAndroid = navigator.userAgent.indexOf("Android") > -1;
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return (
    <div className={styles.invalidContainer}>
      <img src="/assets/404Svg.svg" alt="404" />
      <p>
        נראה שהעסק שניסית להכנס אליו לא קיים! יש לבדוק מול בעל העסק לקישור חדש.
      </p>

      <div className={styles.border}>
        <p>בעל/ת עסק? לחצו על האייקון להורדה והרשמה מהירה 👇</p>

        {isAndroid ? (
          <div className={styles.imgContainer}>
            <a href="https://play.google.com/store/apps/details?id=com.adminappointmentapp">
              <img src="/assets/playstore.svg" alt="playstore" />
            </a>
          </div>
        ) : isIOS ? (
          <div className={styles.imgContainer}>
            <a href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493">
              <img src="/assets/appstore.svg" alt="appstore" />
            </a>
          </div>
        ) : (
          <div className={styles.imgContainer}>
            <a href="https://play.google.com/store/apps/details?id=com.adminappointmentapp">
              <img src="/assets/playstore.svg" alt="playstore" />
            </a>
            <a href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%99%D7%95%D7%9E%D7%9F/id1602074493">
              <img src="/assets/appstore.svg" alt="appstore" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
