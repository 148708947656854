import { format } from "date-fns";

const hebrewMonths = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
];

export const hebrewMonth = (dateValue) =>
    hebrewMonths[Number(format(new Date(dateValue), "M")) - 1];