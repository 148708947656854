import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { paths } from "../routes/paths";
import FullModeImage from "../components/FullModeImage";
import Notice from "../components/Notice";
import BUSINESS from "../api/services/BUSINESS";
import Profile from "../containers/_home/Profile";
import Contact from "../containers/_home/Contact";
import Gallery from "../containers/_home/Gallery";
import Schedule from "../containers/_home/Schedule";
import Layout from "../layout/layout";
import { Action } from "../containers/_home/Action";
import { InvalidBusiness } from "../containers/_home/InvalidBusiness";
import { LoadingBusiness } from "../containers/_home/LoadingBusiness";
import { AddBusiness } from "../containers/_home/AddBusiness";
import { PendingModal } from "../containers/_home/PendingModal";
import { IMAGE_BASE_URL } from "../config";
import "@lottiefiles/lottie-player";
import styles from "../styles/business.module.scss";
import { Helmet } from "react-helmet";

const Business = () => {
  const storedDate = localStorage.getItem("todaysDate");

  const current = new Date();

  const currentFormattedDate = current.toISOString().slice(0, 10);
  const dateEqualltyIsFalsy = storedDate === currentFormattedDate;
  let token = localStorage.getItem("token");

  let openPopup = localStorage.getItem("openPopup");

  const [isOpen, setIsOpen] = useState(openPopup && !dateEqualltyIsFalsy);

  let user_id = localStorage.getItem("user_id");

  let business_id = localStorage.getItem("business_id");

  const buttonRef = useRef();

  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);

  const [invalidBusiness, setInvalidBusiness] = useState(false);

  const { id } = useParams();

  const [sec, setSec] = useState(false);

  const [popupHTML, setPopupHTML] = useState(null);

  const [fullModeImg, setFullModeImg] = useState(null);

  const [currentEmployee, setCurrentEmployee] = useState(null);

  const [isRegistrationPending, setIsRegistrationPending] = useState(null);

  let themeColor = localStorage.getItem("color");
  themeColor = JSON.parse(themeColor);

  const [openAddBusinessModal, setOpenAddBusinessModal] = useState(false);

  const [addBusinessSuccess, setAddBusinessSuccess] = useState(false);

  const [employees, setEmployees] = useState(null);
  const [employeesLoading, setEmployeesLoading] = useState(true);

  const [pendingModal, setPendingModal] = useState(false);

  const [doesBusinessHasSchedule, setDoesBusinessHasSchedule] = useState(null);

  // TIMING FUNCTION
  useEffect(() => {
    setTimeout(() => {
      setSec(true);
    }, 3000);
  }, [sec]);
  useEffect(() => {
    console.log(isOpen, popupHTML, "isOpen");
  }, [isOpen]);

  // FETCH BUSINESS DETAILS FUNCTION
  const fetchBusinessDetails = async (isLoggedIn) => {
    try {
      setLoading(true);
      let res;
      if (isLoggedIn) {
        res = await BUSINESS.details_private(id);
      } else {
        res = await BUSINESS.details(id);
      }

      setDetails(res.data);

      setInvalidBusiness(false);

      localStorage.setItem(
        "flow",
        JSON.stringify(res.data.showCombinedServicesToClients ? 2 : 1)
      );
      // localStorage.setItem("flow", JSON.stringify(1));

      localStorage.setItem("business_code", JSON.stringify(id));
      localStorage.setItem("business_id", JSON.stringify(res.data.id));
      localStorage.setItem(
        "business_name",
        JSON.stringify(res.data.business_name)
      );
      localStorage.setItem(
        "business_cancellation_period",
        JSON.stringify(res.data.business_cancellation_period)
      );
      localStorage.setItem(
        "businesss_schedule_allowed_for",
        JSON.stringify(res.data.businesss_schedule_allowed_for)
      );
      localStorage.setItem(
        "waiting_list_mode",
        JSON.stringify(res.data.waiting_list_mode)
      );
      localStorage.setItem(
        "isRegistrationPending",
        JSON.stringify(res.data.isRegistrationPending)
      );
      localStorage.setItem("color", JSON.stringify(res.data.color));
      localStorage.setItem(
        "disableUserBookings",
        JSON.stringify(res.data.disableUserBookings)
      );

      localStorage.setItem(
        "cancellationText",
        JSON.stringify(res.data.cancellationText)
      );

      setCurrentEmployee(res.data.id);
      setIsRegistrationPending(res.data.isRegistrationPending);
    } catch (error) {
      console.log(error, "ERROR");

      if (
        error.response.status === 400 &&
        error.response.data.message === "invalid credentials"
      ) {
        setInvalidBusiness(true);
      } else if (
        error.response.status === 401 ||
        error.response.data.message === "jwt malformed" ||
        error.response.data.message === "Invalid Token"
      ) {
        localStorage.removeItem("token");
      }
    } finally {
      setLoading(false);
    }
  };

  // FETCH BUSINESS DETAILS
  useEffect(() => {
    setAddBusinessSuccess(false);

    // IF USER LOGGED IN THEN CALL PRIVATE API
    if (token) {
      fetchBusinessDetails(true);
    }
    // ELSE CALL THE PUBLIC API
    else {
      fetchBusinessDetails(false);
    }
  }, [token, addBusinessSuccess]);

  // FETCH EMPLOYEES
  useEffect(() => {
    let business_id = localStorage.getItem("business_id");
    business_id = JSON.parse(business_id);
    if (business_id) {

      const fetchData = async () => {
        try {
          setEmployeesLoading(true)
          const res = await BUSINESS.public_employees(business_id);
          setEmployees(res.data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setEmployeesLoading(false)
        }
      };
      
      fetchData();
    }
  }, [business_id]);

  // FETCH POPUP
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        userId: JSON.parse(user_id),
        business_id: JSON.parse(business_id),
      };
      try {
        const res = await BUSINESS.fetch_popup(payload);

        if (res.data.popUpData) {
          setPopupHTML(res.data.popUpData);
        } else {
          setPopupHTML(null);
        }
      } catch (error) {
        console.log(error);
        setPopupHTML(null);
      }
    };

    fetchData();
  }, []);

  // IF BUSINESS IS INVALID
  if (invalidBusiness) {
    return <InvalidBusiness />;
  }

  // IF LOADING, SHOW LOADER
  if (!details || (!sec && !invalidBusiness)) {
    return <LoadingBusiness />;
  }

  // if (!details || !sec || loading || employeesLoading) {
  //   return <LoadingBusiness />;
  // }

  const DEFAULT_IMAGE_URL =
    "https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>תור דיגיטל - {details?.business_name}</title>
        <link rel="canonical" href={"https://app.tor.digital/" + id} />
      </Helmet>
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* PROFILE */}
            <Profile
              coverImg={
                details.business_image
                  ? details.business_image.includes("data:image") ||
                    details.business_image.includes("https://api.tor.digital/")
                    ? details.business_image
                    : IMAGE_BASE_URL + "/v1/uploads/" + details.business_image
                  : DEFAULT_IMAGE_URL
              }
              profileImg={
                details.profile
                  ? details.profile.includes("data:image") ||
                    details.profile.includes("https://api.tor.digital/")
                    ? details.profile
                    : IMAGE_BASE_URL + "/v1/uploads/" + details.profile
                  : DEFAULT_IMAGE_URL
              }
              businessName={details.business_name}
              description={details.description}
            />

            {/* CONTACT */}
            <Contact
              phone={details?.business_phone}
              whatsapp={details?.whatsApp_phone}
              website={details?.website}
              lat={details?.latitude}
              long={details?.longitude}
              instagram={details?.instagramId}
              tiktok={details?.tiktok}
              facebook={details?.facebook}
              address={details?.address}
            />

            {/* GALLERY */}
            <Gallery
              images={details?.multiImage}
              setFullModeImg={setFullModeImg}
            />

            {/* SCHEDULE */}
            <Schedule
              currentEmployee={currentEmployee}
              setCurrentEmployee={setCurrentEmployee}
              addBusinessSuccess={addBusinessSuccess}
              setAddBusinessSuccess={setAddBusinessSuccess}
              employees={employees}
              setDoesBusinessHasSchedule={setDoesBusinessHasSchedule}
            />

            {/* ACTION */}
            {doesBusinessHasSchedule && (
              <Action
                styles={styles}
                businessID={id}
                addBusinessSuccess={addBusinessSuccess}
                setAddBusinessSuccess={setAddBusinessSuccess}
                isRegistrationPending={isRegistrationPending}
                employees={employees}
                setOpenAddBusinessModal={setOpenAddBusinessModal}
                setPendingModal={setPendingModal}
              />
            )}

            {/* LINKS */}
            <div className={styles.links}>
              <a href={paths.my_appointments}>
                <p>יש לך תור קיים? לחצו כאן כדי לצפות בכל התורים שלך &gt;</p>
              </a>
              <a href="https://join.tor.digital">
                <p>בעל עסק? לחץ כאן להרשמה מהירה &gt;&gt;</p>
              </a>
            </div>
          </div>

          {/* ADD BUSINESS MODAL */}
          {openAddBusinessModal && (
            <AddBusiness
              businessId={id}
              setOpenAddBusinessModal={setOpenAddBusinessModal}
              setAddBusinessSuccess={setAddBusinessSuccess}
            />
          )}

          {/* PENDING MODAL */}
          {pendingModal && <PendingModal setPendingModal={setPendingModal} />}

          {isOpen && popupHTML && (
            <Notice setIsOpen={setIsOpen} popupHTML={popupHTML} />
          )}

          {fullModeImg && (
            <FullModeImage img={fullModeImg} setIsOpen={setFullModeImg} />
          )}
        </div>
      </Layout>
    </>
  );
};

export default Business;
