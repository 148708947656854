import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { paths } from "./paths";
import Login from "../screens/Login";
import Profile from "../screens/Profile";
import Business from "../screens/Business";
import Appointment from "../screens/Appointment";
import MyAppointments from "../screens/MyAppointments";
// import ScreenOne from "../screens/Schedule/ScreenOne";
// import ScreenThree from "../screens/Schedule/ScreenThree";
// import ScreenTwo from "../screens/Schedule/ScreenTwo";
 import ConfirmAppointment from "../screens/ConfirmAppointmentScreen";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path={paths.login} element={<Login />} />

        <Route exact path={paths.business(":id")} element={<Business />} />

        <Route exact path={paths.appointment} element={<Appointment />} />

        <Route
          exact
          path={paths.my_appointments}
          element={<MyAppointments />}
        />

        <Route exact path={paths.profile} element={<Profile />} />

        
        <Route
          exact
          path={paths.confirmation}
          element={<ConfirmAppointment />}
        />

        {/* SCHEDULE AN APPOINTMENT */}
        {/* 
        <Route
          exact
          path={paths.select_employee(":id")}
          element={<ScreenOne />}
        />

        <Route
          exact
          path={paths.select_service(":id")}
          element={<ScreenTwo />}
        />

        <Route
          exact
          path={paths.select_date(":id")}
          element={<ScreenThree />}
        /> */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
