import Lottie from "lottie-react";
import loadingAnimation from "../../lottie/loading.json";
import styles from "../../styles/loadingBusiness.module.scss";

export const LoadingBusiness = () => {
  return (
    <div className={styles.loadingContainer}>
      <Lottie
        animationData={loadingAnimation}
        loop={true}
        style={{ height: 150 }}
      />
    </div>
  );
};
