import { useEffect, useState, forwardRef, useContext } from "react";
import { ClockIcon } from "../../svgs/clock";
import {
  generateProfileImgUrl,
  ProfileImage,
} from "../../utils/generateProfileImgUrl";
import BUSINESS from "../../api/services/BUSINESS";
import { format } from "date-fns";
import { Calendar } from "react-calendar";
import { ThemeContext } from "../../App";
import styles from "../../styles/selectTimeSection.module.scss";
import backgroundImage from "../../images/blur2.png";

const SelectTime = forwardRef((props, ref) => {
  let {
    timeslot,
    setTimeslot,
    dateValue,
    onChangeDateValue,
    currentEmployee,
    currentService,
    setWaitingListModalSlot,
    setWaitingListModalDay,
  } = props;

  let color = useContext(ThemeContext);

  const [timeslots, setTimeslots] = useState([]);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showWaitingListBtn, setShowWaitingListBtn] = useState(false);
  const [showNoAvailableSlotsMessage, setShowNoAvailableSlotsMessage] =
    useState(false);

  let waitingListMode = localStorage.getItem("waiting_list_mode");
  waitingListMode = JSON.parse(waitingListMode);

  // FETCH TIMESLOTS
  useEffect(() => {
    if (dateValue) {
      const fetchData = async () => {
        const payload = {
          employeeId: currentEmployee.id,
          serviceId: currentService._id,
          date: format(new Date(dateValue), "yyyy-MM-dd"),
        };
        setSlotsLoading(true);
        try {
          const res = await BUSINESS.employee_timeslots(payload);
          setErrorMessage(res.data.message); // clear any previous error

          if (res && res.data && res.data.message) {
            setErrorMessage(res.data.message);
          }

          if (waitingListMode === "DAY" && res.data.data.allUnavailable) {
            setShowWaitingListBtn(true);
            setShowNoAvailableSlotsMessage(false);
            setTimeslots([]);
          } else if (
            waitingListMode === "TIME" &&
            res.data.data.allUnavailable
          ) {
            setShowWaitingListBtn(false);
            setShowNoAvailableSlotsMessage(false);
            setTimeslots(res.data.data.detailedRecords);
          } else if (
            waitingListMode === "DISABLED" &&
            res.data.data.allUnavailable
          ) {
            setShowNoAvailableSlotsMessage(true);
            setShowWaitingListBtn(false);
            setTimeslots([]);
          } else {
            setShowNoAvailableSlotsMessage(false);
            setShowWaitingListBtn(false);
            setTimeslots(res.data.data.detailedRecords);
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            // Set the specific error message from server
            setErrorMessage(error.response.data.message);
          } else {
            // Set a generic error message if no specific message from server
            setErrorMessage("לא ניתן לקבוע תור. יש לבדוק מול בעל העסק");
          }
        }

        setSlotsLoading(false);
      };

      fetchData();
    }
  }, [dateValue, currentEmployee, currentService]);

  return (
    <div className={styles.container} ref={ref}>
      <h2>בחירת יום ושעה</h2>
      <p className={styles.mainText}>בוחרים תאריך ולאחר מכן את השעה</p>

      <div className={styles.card}>
        {/* SUMMARY */}
        <Summary
          currentEmployee={currentEmployee}
          currentService={currentService}
          color={color}
        />

        {/* CALENDAR */}
        <CalendarComponent
          dateValue={dateValue}
          onChangeDateValue={onChangeDateValue}
          currentEmployee={currentEmployee}
          color={color}
        />

        {/* If there's an error message, display it */}
        {errorMessage ? (
          <div
            style={{
              color: "red",
              margin: "10px 0",
              textAlign: "center",
              fontSize: "24px",
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "contain",
              height: "200px",
              fontWeight: "600",
              alignItems: "center",
              display: "flex",
            }}
          >
            {errorMessage}
          </div>
        ) : (
          /* If there's no error message but a dateValue exists, display the TimeSlots component */
          dateValue && (
            <TimeSlots
              slots={timeslots}
              setSlots={setTimeslots}
              timeslot={timeslot}
              setTimeslot={setTimeslot}
              dateValue={dateValue}
              waitingListMode={waitingListMode}
              setWaitingListModalSlot={setWaitingListModalSlot}
              setWaitingListModalDay={setWaitingListModalDay}
              color={color}
              slotsLoading={slotsLoading}
              currentService={currentService}
              currentEmployee={currentEmployee}
              showWaitingListBtn={showWaitingListBtn}
              showNoAvailableSlotsMessage={showNoAvailableSlotsMessage}
            />
          )
        )}
      </div>
    </div>
  );
});

export default SelectTime;

// SUMMARY COMPONENT
const Summary = ({ currentEmployee, currentService, color }) => {
  return (
    <div className={styles.summary}>
      <div className={styles.employee}>
        {/* <img
          src={generateProfileImgUrl(currentEmployee.profile)}
          alt={currentEmployee.name}
        /> */}
        <ProfileImage
          img={currentEmployee.profile}
          name={currentEmployee.name}
        />

        <p style={{ lineHeight: "100%" }}>{currentEmployee.name}</p>
      </div>

      <span className={`${styles.line} ${styles.firstLine}`} />

      <div className={styles.service}>
        <h5>{currentService.name}</h5>
        {/* <p>{currentService.description}</p> */}
      </div>

      <span className={`${styles.line} ${styles.secondLine}`} />

      <div className={styles.serviceOthers}>
        <div
          className={styles.block}
          style={{
            border: `1px solid ${color.mainColor}`,
            backgroundColor: color.subColor,
          }}
        >
          <ClockIcon fill={color.mainColor} />
          <p style={{ color: color.mainColor }}>{currentService.time} דקות</p>
        </div>
        {currentService.price !== "0" && (
          <div
            className={styles.block}
            style={{
              border: `1px solid ${color.mainColor}`,
              backgroundColor: color.subColor,
            }}
          >
            <p style={{ color: color.mainColor }}>
              {currentService.isExactPrice ? " " : "החל מ-"}₪
              {currentService.price}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// TIMESLOTS COMPONENT
const TimeSlots = ({
  slots,
  setSlots,
  timeslot,
  setTimeslot,
  dateValue,
  waitingListMode,
  setWaitingListModalSlot,
  setWaitingListModalDay,
  color,
  slotsLoading,
  currentService,
  currentEmployee,

  showWaitingListBtn,
  showNoAvailableSlotsMessage,
}) => {

  const [isHovered, setIsHovered] = useState(null);
  const [filteredSlots, setFilteredSlots] = useState([]);

  let flow = localStorage.getItem("flow");
  flow = JSON.parse(flow);

  // REMOVE THE CURRENT DAY SLOTS THAT ARE IN PAST
  useEffect(() => {
    setFilteredSlots(slots);
  }, [dateValue, slots, currentService, currentEmployee]);

  // handle slot click
  const handleClick = (i) => {
    if (!i.available) {
      setWaitingListModalSlot(i.slot);
      setTimeslot(i.slot);
    } else {
      setTimeslot(i.slot);
    }
  };

  if (slotsLoading) return null;
  return (
    <div className={styles.slots}>
      <h4>בחירת שעה</h4>

      <div className={styles.items}>
        {showWaitingListBtn && (
          <div className={styles.noSlots}>
            <div className={styles.waitingListSignup}>
              <p>רוצה שנעדכן אותך כאשר מתפנה תור?</p>
              <button
                onClick={() => setWaitingListModalDay(true)}
                style={{ backgroundColor: color.mainColor }}
              >
                הרשמו לרשימת ההמתנה
              </button>
            </div>
          </div>
        )}

        {showNoAvailableSlotsMessage && (
          <div className={styles.noSlots}>
            <p>לא נותרו תורים פנויים להיום.</p>
          </div>
        )}

        {filteredSlots.map((i) => (
          <div
            key={i.slot}
            className={styles.slot}
            onClick={() => handleClick(i)}
            style={{
              backgroundColor:
                timeslot === i.slot
                  ? color.mainColor
                  : isHovered === i.slot
                  ? color.mainColor
                  : "",
              display:
                (waitingListMode === "DAY" || waitingListMode === "DISABLED") &&
                !i.available &&
                "none",

              border:
                waitingListMode === "TIME" &&
                !i.available &&
                "none" &&
                `1px solid ${color.mainColor}`,
            }}
            onMouseEnter={() => setIsHovered(i.slot)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <p
              style={{
                color:
                  timeslot === i.slot
                    ? "#fff"
                    : isHovered === i.slot
                    ? "#fff"
                    : waitingListMode === "TIME" && !i.available && "none"
                    ? color.mainColor
                    : "",
              }}
            >
              {i.slot}
              <br />
              {waitingListMode === "TIME" && !i.available ? (
                <small>ר.המתנה</small>
              ) : (
                ""
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

// CALENDAR COMPONENT
const CalendarComponent = ({
  dateValue,
  onChangeDateValue,
  currentEmployee,
  color,
}) => {
  const [disabledDays, setDisabledDays] = useState([0, 1, 2, 3, 4, 5, 6]);

  const [businessSchedule, setBusinessSchedule] = useState(null);

  // business schedule
  useEffect(() => {
    const fetchData = async () => {
      try {
        const business_schedule = await BUSINESS.schedule(currentEmployee.id);

        const updated_business_schedule = business_schedule.data[0].days.filter(
          (x) => {
            return x.status;
          }
        );

        setBusinessSchedule(updated_business_schedule);
      } catch (error) {}
    };

    fetchData();
  }, [currentEmployee]);

  let businesssScheduleAllowedFor = localStorage.getItem(
    "businesss_schedule_allowed_for"
  );

  // === calculate business schedule max date ===
  businesssScheduleAllowedFor = JSON.parse(businesssScheduleAllowedFor);

  // take out the unit of time from string
  let unit = businesssScheduleAllowedFor.charAt(
    businesssScheduleAllowedFor.length - 1
  );

  // remove last character from string
  let withoutUnit = businesssScheduleAllowedFor.slice(0, -1);

  // convert to days
  let days;

  if (unit === "m") {
    days = withoutUnit * 30;
  } else if (unit === "w") {
    days = withoutUnit * 7;
  } else if (unit === "y") {
    days = withoutUnit * 365;
  }

  // calculate the max date
  let maxDate = new Date(); // Current date
  maxDate.setDate(maxDate.getDate() + days); // Add 7 days

  // set disabled days
  useEffect(() => {
    if (businessSchedule) {
      disableDays(businessSchedule);
    }
  }, [businessSchedule, currentEmployee]);

  let disableDays = (days) => {
    setDisabledDays([0, 1, 2, 3, 4, 5, 6]);
    if (days.some((e) => e.name === "monday")) {
      setDisabledDays((current) => current.filter((item) => item !== 1));
    }

    if (days.some((e) => e.name === "tuesday")) {
      setDisabledDays((current) => current.filter((item) => item !== 2));
    }

    if (days.some((e) => e.name === "wednesday")) {
      setDisabledDays((current) => current.filter((item) => item !== 3));
    }

    if (days.some((e) => e.name === "thursday")) {
      setDisabledDays((current) => current.filter((item) => item !== 4));
    }

    if (days.some((e) => e.name === "friday")) {
      setDisabledDays((current) => current.filter((item) => item !== 5));
    }

    if (days.some((e) => e.name === "saturday")) {
      setDisabledDays((current) => current.filter((item) => item !== 6));
    }

    if (days.some((e) => e.name === "sunday")) {
      setDisabledDays((current) => current.filter((item) => item !== 0));
    }
  };

  return (
    <div className={styles.calendar}>
      <h4>בחירת יום</h4>
      <Calendar
        value={dateValue}
        onChange={onChangeDateValue}
        // className={`mainCalendar ${styles.cal}`}

        className={
          color.mainColor === "#d00254"
            ? "cal1"
            : color.mainColor === "#007bff"
            ? "cal2"
            : color.mainColor === "#e273AA"
            ? "cal3"
            : color.mainColor === "#111827"
            ? "cal4"
            : color.mainColor === "#28a745"
            ? "cal5"
            : color.mainColor === "#c82333"
            ? "cal6"
            : color.mainColor === "#fd7e14"
            ? "cal7"
            : color.mainColor === "#9932cc"
            ? "cal8"
            : color.mainColor === "#884a39"
            ? "cal9"
            : "calDefault"
        }
        calendarType="Hebrew"
        formatDay={(locale, date) => format(date, "d")}
        next2Label={null}
        prev2Label={null}
        nextLabel="<"
        prevLabel=">"
        // onClickDay={(val) => {
        //   getTimeSlots(val);
        // }}
        formatShortWeekday={(locale, date) => {
          if (format(date, "E").toLowerCase() === "sun") {
            return "א";
          } else if (format(date, "E").toLowerCase() === "mon") {
            return "ב";
          } else if (format(date, "E").toLowerCase() === "tue") {
            return "ג";
          } else if (format(date, "E").toLowerCase() === "wed") {
            return "ד";
          } else if (format(date, "E").toLowerCase() === "thu") {
            return "ה";
          } else if (format(date, "E").toLowerCase() === "fri") {
            return "ו";
          } else if (format(date, "E").toLowerCase() === "sat") {
            return "ש";
          }
        }}
        formatMonthYear={(locale, date) => {
          if (format(date, "MMMM yyyy") === `January ${format(date, "yyyy")}`) {
            return `${format(date, "yyyy")} ינואר`;
          } else if (
            format(date, "MMMM yyyy") === `February ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} פברואר`;
          } else if (
            format(date, "MMMM yyyy") === `March ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} מרץ`;
          } else if (
            format(date, "MMMM yyyy") === `April ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אפריל`;
          } else if (
            format(date, "MMMM yyyy") === `May ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} מאי`;
          } else if (
            format(date, "MMMM yyyy") === `June ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} יוני`;
          } else if (
            format(date, "MMMM yyyy") === `July ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} יולי`;
          } else if (
            format(date, "MMMM yyyy") === `August ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אוגוסט`;
          } else if (
            format(date, "MMMM yyyy") === `September ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} ספטמבר`;
          } else if (
            format(date, "MMMM yyyy") === `October ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אוקטובר`;
          } else if (
            format(date, "MMMM yyyy") === `November ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} נובמבר`;
          } else if (
            format(date, "MMMM yyyy") === `December ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} דצמבר`;
          }
        }}
        tileDisabled={({ activeStartDate, date, view }) =>
          disabledDays.some((i) => date.getDay() === i)
        }
        minDate={new Date()}
        maxDate={maxDate}
      />
    </div>
  );
};
