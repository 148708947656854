import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import { paths } from "../../routes/paths";
import { toast } from "react-toastify";
import AUTH from "../../api/services/AUTH";
import Spinner from "../../components/Spinner";

export const OTPCard = ({
  styles,
  OTP,
  setOTP,
  phone,
  loading,
  setLoading,
  setStep,
}) => {
  const navigate = useNavigate();

  const color = useContext(ThemeContext);

  let isClicked = localStorage.getItem("isClicked");

  let businessCode = localStorage.getItem("business_code");

  const current = new Date();
  const currentFormattedDate = current.toISOString().slice(0, 10);
  const checkIfExistsInLocalStorage = localStorage.getItem("todaysDate")
    ? true
    : false;

  const handleVerify = async () => {
    if (OTP.trim()) {
      setLoading(true);
      try {
        const payload = {
          phone,
          countryCode: "91",
          otp: OTP,
        };

        const res = await AUTH.verify(payload);
        if (res.status === 201) {
          localStorage.setItem(
            "token",
            JSON.stringify(res.data.token.access.token)
          );

          localStorage.setItem("user_id", JSON.stringify(res.data.user._id));

          toast.success("קוד תקין, מתחבר");

          if (!res.data.user.first_time) {
            setStep(2);
          } else {
            // if (isClicked) {
            //   navigate(paths.appointment);
            //   localStorage.removeItem("isClicked");
            //   localStorage.setItem("openPopup", true);
            // } else {
            navigate(paths.business(JSON.parse(businessCode)));
            localStorage.setItem("openPopup", true);
            if (checkIfExistsInLocalStorage === false) {
              localStorage.setItem("todaysDate", currentFormattedDate);
            }
          }
          // }
        }
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    } else {
      alert("נא להזין קוד תקין");
    }
  };

  return (
    <div className={styles.card}>
      <h2>קוד התחברות</h2>
      <p>אנא הזן את הקוד שקיבלת לטלפון</p>
      <input
        type="number"
        value={OTP}
        onChange={(e) => setOTP(e.target.value)}
        placeholder="בדוק קוד"
      />
      <button
        onClick={handleVerify}
        disabled={!OTP}
        style={{ backgroundColor: color?.mainColor }}
      >
        {loading ? <Spinner /> : "בדוק"}
      </button>
    </div>
  );
};
