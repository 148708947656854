export const LinkIcon = ({ fill }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3643 19.0548L7.41844 22.0007C6.19714 23.222 4.21935 23.222 2.99908 22.0009C1.77856 20.7804 1.77856 18.8024 2.99883 17.5821L8.89159 11.6894C10.1119 10.4691 12.0899 10.4691 13.3101 11.6894C13.7169 12.0962 14.3765 12.0962 14.7833 11.6894C15.1901 11.2826 15.1901 10.623 14.7833 10.2162C12.7494 8.18232 9.45234 8.18232 7.41844 10.2162L1.52573 16.1089C-0.508169 18.1428 -0.508169 21.4399 1.52573 23.4738C3.55938 25.5087 6.85667 25.5087 8.89164 23.4738L11.8375 20.5279C12.2443 20.1211 12.2443 19.4615 11.8375 19.0547C11.4307 18.648 10.7711 18.648 10.3643 19.0548Z"
        fill={fill}
      />
      <path
        d="M23.4743 1.52542C21.4404 -0.508475 18.1423 -0.508475 16.1084 1.52542L12.574 5.05982C12.1672 5.46661 12.1672 6.12618 12.574 6.53297C12.9808 6.93976 13.6403 6.93976 14.0471 6.53297L17.5815 2.99858C18.8018 1.77826 20.7808 1.77826 22.0011 2.99858C23.2214 4.21885 23.2214 6.19684 22.0011 7.41711L15.5198 13.8984C14.2995 15.1188 12.3216 15.1188 11.1013 13.8984C10.6945 13.4917 10.0349 13.4917 9.62815 13.8984C9.22136 14.3052 9.22136 14.9648 9.62815 15.3716C11.662 17.4055 14.9591 17.4055 16.993 15.3716L23.4743 8.89031C25.5082 6.85641 25.5082 3.55932 23.4743 1.52542Z"
        fill={fill}
      />
    </svg>
  );
};
