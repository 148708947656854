import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import USER from "../api/services/USER";
import Header from "./header";
import AppStoreBar from "./appStoreBar";
import SideMenu from "./sideMenu";
import styles from "../styles/layout.module.scss";

const Layout = ({ children }) => {
  const { id } = useParams();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isAppStoreBarOpen, setIsAppStoreBarOpen] = useState(true);

  let businessName = localStorage.getItem("business_name");
  let isRegistrationPending = localStorage.getItem("isRegistrationPending");
  isRegistrationPending = JSON.parse(isRegistrationPending);
  let themeColor = localStorage.getItem("color");
  themeColor = JSON.parse(themeColor);
  let token = localStorage.getItem("token");

  const [doesBusinessExist, setDoesBusinessExist] = useState(null);

  // fetch businesses list
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          const res = await USER.get_businesses_list();

          let businesses = res.user.map((i) =>
            i.businessId ? i.businessId.businessId : null
          );
          businesses = businesses.filter((i) => i !== null);

          let doesExist = businesses.includes(id);

          if (doesExist) {
            setDoesBusinessExist("yes");
          } else {
            setDoesBusinessExist("no");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [token]);

  return (
    <>
      {/* IF USER LOGGED IN, BUSINESS IS ADDED AND REGISTRATION IS PENDING */}
      {token &&
        doesBusinessExist &&
        doesBusinessExist === "yes" &&
        isRegistrationPending && (
          <div className={styles.message}>
            <p>הרשמתך ממתינה לאישור בעל העסק</p>
          </div>
        )}

      {/* APPSTORE BAR */}
      {isAppStoreBarOpen && <AppStoreBar setIsOpen={setIsAppStoreBarOpen} />}

      {/* MAIN HEADER */}
      <Header setIsOpen={setIsMenuOpen} />

      {/* SIDE MENU */}
      {isMenuOpen && <SideMenu setIsOpen={setIsMenuOpen} />}

      {children}
    </>
  );
};

export default Layout;
