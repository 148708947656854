export default function isValidIsraeliPhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  phoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the phone number starts with "0" and has a length of 10
  if (phoneNumber.startsWith("0") && phoneNumber.length === 10) {
    // Check if the second digit is "5" or "7"
    var secondDigit = phoneNumber.charAt(1);
    if (secondDigit === "5" || secondDigit === "7") {
      return true;
    }
  }

  return false;
}
