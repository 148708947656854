import { useEffect, useState, useContext, forwardRef } from "react";
import { ThemeContext } from "../../App";
import { ClockIcon } from "../../svgs/clock";
import BUSINESS from "../../api/services/BUSINESS";
import styles from "../../styles/servicesSection.module.scss";
import Loader from "../../components/Loader";



const Services = forwardRef((props, ref) => {
  let {
    currentEmployee,
    setCurrentEmployee,
    currentService,
    setCurrentService,
    selectTimeRef,
  } = props;

  const [services, setServices] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log(services,'services')

  let businessID = localStorage.getItem("business_id");
  businessID = JSON.parse(businessID);

  let flow = localStorage.getItem("flow");
  flow = JSON.parse(flow);

  // FETCH FLOW 1 SERVICES API
  const fetchFlowOneServices = async () => {
    setLoading(true);
    try {
      const res = await BUSINESS.employee_services(currentEmployee.id);

      if (res.data.length === 0) {
        setCurrentService(null);
        setServices("no-services");
      } else {
        setServices(res.data);
      }
    } catch (error) {
      setCurrentService(null);
      console.log(error);
    }
    setLoading(false);
  };

  // FETCH FLOW 2 SERVICES API
  const fetchFlowTwoServices = async () => {
    setLoading(true);
    let payload = {
      business_id: businessID,
    };
    try {
      const res = await BUSINESS.employee_services_2(payload);

      if (res.data.data.length === 0) {
        setCurrentService(null);
        setServices("no-services");
      } else {
        setServices(res.data.data);
      }
    } catch (error) {
      setCurrentService(null);
      console.log(error);
    }
    setLoading(false);
  };

  // FETCH SERVICES
  useEffect(() => {
    if (flow === 1) {
      fetchFlowOneServices();
    } else {
      fetchFlowTwoServices();
    }
  }, [flow === 1 ? currentEmployee : null, flow]);

  // IF LOADING
  if (loading || !services) return <Loader />;

  // IF NO SERVICES
  if (services === "no-services") {
    return (
      <div className={styles.container} ref={ref}>
        <p className={styles.noServices}>לא נמצאו שירותים לעובד זה</p>
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref}>
      <h2>בחירת שירות</h2>
      <p className={styles.mainText}>ניתן לבחור שירות/שיעור אחד בלבד</p>

      <div className={styles.services}>
        {services.map((i) => (
          <ServiceCard
            key={i._id}
            id={i._id}
            row={i}
            currentService={currentService}
            name={i.name}
            price={i.price}
            desc={i.description}
            isExactPrice={i.isExactPrice}
            maxQueue={i.maximumQueue}
            forSubscriptionOnly={i.forSubscriptionOnly}
            time={i.time}
            flow={flow}
            handleClick={() => {
              if (flow === 2) {
                setCurrentEmployee(i.employeeId);
              }
              setCurrentService(i);
              setTimeout(() => {
                selectTimeRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }}
          />
        ))}
      </div>
    </div>
  );
});

export default Services;

// SERVICE CARD
export const ServiceCard = ({
  id,
  row,
  currentService,
  name,
  price,
  desc,
  isExactPrice,
  maxQueue,
  forSubscriptionOnly,
  time,
  handleClick,
  flow,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  let color = useContext(ThemeContext);

  // FLOW ONE
  if (flow === 1) {
    return (
      <div
        className={`${styles.card} ${
          id === currentService?._id ? styles.active : ""
        }`}
        onClick={handleClick}
        style={{
          border:
            currentService?._id === id
              ? `1px solid ${color.mainColor}`
              : isHovered === id
              ? `1px solid ${color.mainColor}`
              : "",
          backgroundColor: currentService?._id === id ? color.subColor : "",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(id)}
        onMouseLeave={() => setIsHovered(null)}
      >
        {forSubscriptionOnly && (
          <div className={styles.block}>
            <span
              style={{
                position: "absolute",
                top: "-6px",
                right: "0",
                backgroundColor: "#DE1468",
                color: "white",
                borderRadius: "6px",
                padding: "0px 5px",
                fontSize: "13px",
                transform: "rotate(5deg)", // This will rotate the span downwards by 5 degrees
              }}
            >
              {" "}
              מנויים בלבד
            </span>
          </div>
        )}
        <div
          className={styles.img}
          style={{ backgroundColor: color.mainColor }}
        >
          <h3>{name?.slice(0, 1)}</h3>
        </div>

        <div className={styles.content}>
          <h5 className={styles.name}>{name}</h5>
          <p className={styles.desc}>{desc}</p>

          <div className={styles.row}>
            <div
              className={styles.block}
              style={{
                backgroundColor: color.subColor,
                border: `1px solid ${color.mainColor}`,
              }}
            >
              <ClockIcon fill={color.mainColor} />
              <p style={{ color: color.mainColor }}>{time} דקות</p>
            </div>

            {/* {flow === 2 && (
              <div
                className={styles.block}
                style={{
                  backgroundColor: color.subColor,
                  border: `1px solid ${color.mainColor}`,
                }}
              >
                <ClockIcon fill={color.mainColor} />
                <p style={{ color: color.mainColor }}>{maxQueue} משתתפים</p>
              </div>
            )} */}
            {price > 0 && (
              <div
                className={styles.block}
                style={{
                  backgroundColor: color.subColor,
                  border: `1px solid ${color.mainColor}`,
                  maxWidth: !isExactPrice ? "120px" : "60px",
                  minWidth: !isExactPrice ? "100px" : "60px",
                }}
              >
                <p style={{ color: color.mainColor }}>
                  {isExactPrice ? " " : "החל מ-"}₪{price}
                </p>
              </div>
            )}
          </div>
        </div>
        {currentService?._id !== id && (
          <button style={{ border: "1px solid #140635" }}>
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439341 10.9393C-0.146446 11.5251 -0.146446 12.4749 0.439341 13.0607L9.98528 22.6066C10.5711 23.1924 11.5208 23.1924 12.1066 22.6066C12.6924 22.0208 12.6924 21.0711 12.1066 20.4853L3.62132 12L12.1066 3.51472C12.6924 2.92893 12.6924 1.97919 12.1066 1.3934C11.5208 0.807611 10.5711 0.807611 9.98528 1.3934L0.439341 10.9393ZM25.5 10.5L1.5 10.5V13.5L25.5 13.5V10.5Z"
                fill="#140635"
              />
            </svg>
          </button>
        )}
        {currentService?._id === id && (
          <button style={{ border: `1px solid ${color.mainColor}` }}>
                     <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439341 10.9393C-0.146446 11.5251 -0.146446 12.4749 0.439341 13.0607L9.98528 22.6066C10.5711 23.1924 11.5208 23.1924 12.1066 22.6066C12.6924 22.0208 12.6924 21.0711 12.1066 20.4853L3.62132 12L12.1066 3.51472C12.6924 2.92893 12.6924 1.97919 12.1066 1.3934C11.5208 0.807611 10.5711 0.807611 9.98528 1.3934L0.439341 10.9393ZM25.5 10.5L1.5 10.5V13.5L25.5 13.5V10.5Z"
                fill={color.mainColor}
              />
            </svg>
          </button>
        )}
      </div>
    );
  }

  // FLOW TWO
  else {
    return (
      <div
        className={`${styles.card} ${
          id === currentService?._id ? styles.active : ""
        } ${styles.flow2}`}
        onClick={handleClick}
        style={{
          border:
            currentService?._id === id
              ? `1px solid ${color.mainColor}`
              : isHovered === id
              ? `1px solid ${color.mainColor}`
              : "",
        }}
        onMouseEnter={() => setIsHovered(id)}
        onMouseLeave={() => setIsHovered(null)}
      >
        {/* RIGHT */}
        <div className={styles.right}>
          {/* ROW ONE */}
          <div className={`${styles.row} ${styles.rowOne}`}>
            {/* IMAGE */}
            <div
              className={styles.img}
              style={{ backgroundColor: color.mainColor }}
            >
              <h3>{name?.slice(0, 1)}</h3>
            </div>
            {/* NAME AND DESC */}
            <div className={styles.content}>
              <h5 className={styles.name}>{name}</h5>
              <p className={styles.desc}>{desc}</p>
            </div>
          </div>

          {/* ROW TWO */}
          <div className={`${styles.row} ${styles.rowTwo}`}>
            <div
              className={styles.block}
              style={{
                backgroundColor: color.subColor,
                border: `1px solid ${color.mainColor}`,
              }}
            >
              <ClockIcon fill={color.mainColor} />
              <p style={{ color: color.mainColor,paddingLeft:'3px',fontWeight:'300' }}>{time} דקות</p>
            </div>
            <div
              className={styles.block}
              style={{
                backgroundColor: color.subColor,
                border: `1px solid ${color.mainColor}`,
              }}
            >
              <ClockIcon fill={color.mainColor} />
              <p style={{ color: color.mainColor,paddingLeft:'3px',fontWeight:'300',marginRight:'-2px' }}> {maxQueue} משתתפים </p>
            </div>

            {price > 0 && (
            <div
              className={styles.block}
              style={{
                backgroundColor: color.subColor,
                border: `1px solid ${color.mainColor}`,
                maxWidth: !isExactPrice ? "120px" : "60px",
                minWidth: !isExactPrice ? "100px" : "60px",
              }}
            >
              <p style={{ color: color.mainColor,fontWeight:'300' }}>
                {isExactPrice ? " " : "החל מ-"}₪{price}
              </p>
            </div>
          )}
          </div>
        </div>

        {/* LEFT */}
        <div className={styles.left}>
        <div
             
              style={{
                backgroundColor: '#66C00C',
                border: `1px solid rgb(61 180 36)`,
                minWidth:'60px',
                justifyContent:'center',
                alignItems:'center',
                borderRadius:'50px',
                padding:'2px'
              }}
            >
              
              <p style={{ color: '#FFFFFF', fontSize:'13px', fontWeight:'600',textAlign:'center' }}>{row.employeeId.name}</p>
            </div>
            <button style={{ border: "1px solid #140635" }}>
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439341 10.9393C-0.146446 11.5251 -0.146446 12.4749 0.439341 13.0607L9.98528 22.6066C10.5711 23.1924 11.5208 23.1924 12.1066 22.6066C12.6924 22.0208 12.6924 21.0711 12.1066 20.4853L3.62132 12L12.1066 3.51472C12.6924 2.92893 12.6924 1.97919 12.1066 1.3934C11.5208 0.807611 10.5711 0.807611 9.98528 1.3934L0.439341 10.9393ZM25.5 10.5L1.5 10.5V13.5L25.5 13.5V10.5Z"
                fill="#140635"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
};
