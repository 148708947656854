import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "../routes/paths";
import { IMAGE_BASE_URL } from "../config";
import { ThemeContext } from "../App";
import { PhoneIcon } from "../svgs/phone";
import { EmailIcon } from "../svgs/emailIcon";
import USER from "../api/services/USER";
import AUTH from "../api/services/AUTH";
import Layout from "../layout/layout";
import styles from "../styles/profile.module.scss";
import { ConfirmModal } from "../containers/_profile/ConfirmModal";

const Profile = () => {
  const DEFAULT_IMAGE_URL =
    "https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg";

  const navigate = useNavigate();
  let businessCode = localStorage.getItem("business_code");
  businessCode = JSON.parse(businessCode);

  // IF THERE IS NO BUSINESS CODE THEN REDIRECT TO LOGIN PAGE
  useEffect(() => {
    if (!businessCode) {
      navigate(paths.login);
    }
  }, [businessCode]);

  const [data, setData] = useState(null);
  const color = useContext(ThemeContext);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [nameVal, setNameVal] = useState("");
  const [editSuccess, setEditSuccess] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // FETCH USER DETAILS
  useEffect(() => {
    setEditSuccess(null);
    const fetchData = async () => {
      try {
        const res = await USER.profile();
        setData(res.user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [editSuccess]);

  // UPDATE PROFILE
  const updateProfile = async () => {
    let payload = {
      businessId: businessCode,
      name: nameVal,
    };
    try {
      const res = await AUTH.update_profile(payload);
      if (res.data.code === 200) {
        setIsEditOpen(false);
        setEditSuccess(true);
        setNameVal("");
        toast.success("השם עודכן בהצלחה");
      }
    } catch (error) {
      console.log(error);
      setIsEditOpen(false);
      toast.error(error.response.data.message);
    }
  };

  // HANDLE IMAGE CHANGE
  const handleImgChange = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = async function () {
      try {
        const res = await USER.update_image({ file: reader.result });
        setEditSuccess(true);
        toast.success("תמונת הפרופיל עודכנה בהצלחה");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    };
    reader.readAsDataURL(file);
  };

  // DELETE ACCOUNT
  const deleteAccount = async () => {
    try {
      const res = await USER.delete_account();
      if (res.status === 200) {
        setIsModalOpen(false);
        navigate(paths.login);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!data) return null;

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>הפרופיל שלי</h1>
          <div className={styles.card}>
            <div
              className={styles.head}
              style={{ backgroundColor: color.mainColor }}
            >
              {/* PROFILE IMAGE */}
              <div className={styles.profileImg}>
                <img
                  src={
                    data.profile
                      ? IMAGE_BASE_URL + "/v1/uploads/" + data.profile
                      : DEFAULT_IMAGE_URL
                  }
                  alt={data.name}
                  className={styles.profile}
                />
                <img
                  src="/assets/camera.svg"
                  alt="camera-icon"
                  className={styles.cam}
                />
                <input type="file" onChange={handleImgChange} />
              </div>

              {/* NAME AND EDIT NAME FIELD */}
              {isEditOpen ? (
                <div className={styles.editField}>
                  <input
                    type="text"
                    value={nameVal}
                    onChange={(e) => setNameVal(e.target.value)}
                  />
                </div>
              ) : (
                <h4>
                  {data.name}
                  <span onClick={() => setIsEditOpen(true)}>🖊️</span>
                </h4>
              )}
            </div>

            <div className={styles.body}>
              {/* PHONE */}
              <div className={styles.row}>
                <PhoneIcon fill="#8f8f8f" />
                <p>{data.phone}</p>
              </div>

              {/* EMAIL */}
              {data.email && (
                <div className={styles.row}>
                  <EmailIcon fill="#8f8f8f" />
                  <p>{data.email}</p>
                </div>
              )}

              {/* UPDATE BUTTON */}
              {nameVal && (
                <button
                  className={styles.update}
                  style={{
                    border: `1px solid ${color.mainColor}`,
                    color: color.mainColor,
                  }}
                  onClick={updateProfile}
                >
                  עדכון פרטים
                </button>
              )}

              {/* DELETE BUTTON */}
              <button
                className={styles.delete}
                onClick={() => setIsModalOpen(true)}
              >
                מחיקת משתמש
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <ConfirmModal
            setIsOpen={setIsModalOpen}
            handleClick={deleteAccount}
          />
        )}
      </div>
    </Layout>
  );
};

export default Profile;
