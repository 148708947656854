import { useContext } from "react";
import { ThemeContext } from "../../App";
import { hebrewMonth } from "../../utils/getHebrewMonth";
import { hebrewDay } from "../../utils/getHebrewDay";
import { format } from "date-fns";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import DownloadApp from "../../components/downloadApp";
import styles from "../../styles/createAppointmentModal.module.scss";

const CreateAppointmentModal = ({
  setOpen,
  employee,
  service,
  serviceDetails,
  time,
  price,
  dateValue,
  timeslot,
  comment,
  setComment,
  handleClick,
  loading,
  setLoading,
  setPolicyModalOpen,
  setIsPaymentModalOpen,
}) => {
  let color = useContext(ThemeContext);

  const cardStyle = {
    border: `1px solid ${color.mainColor}`,
    backgroundColor: color.subColor,
  };

  const textStyle = {
    color: color.mainColor,
  };

  return (
    <Modal setIsOpen={setOpen}>
      <div className={styles.modalContainer}>
        <div className={styles.modalWrapper}>
          <h2>
        
          {hebrewDay(dateValue)} {format(new Date(dateValue), "dd")} ל{hebrewMonth(dateValue)}
         <br/>
             בשעה {timeslot}
          </h2>
          <p className={styles.reminder}>תשלח הודעת אישור לנייד</p>

          <div className={styles.cards}>
            {/* employee */}
            <div className={styles.card} style={cardStyle}>
              <h3>נותן השירות</h3>
              <p style={textStyle}>{employee}</p>
            </div>
            {/* service */}
            <div className={styles.card} style={cardStyle}>
              <h3>סוגי שירות</h3>
              <p style={textStyle}>{service}</p>
            </div>
            {/* time */}
            <div className={styles.card} style={cardStyle}>
              <h3>אורך</h3>
              <p style={textStyle}>{time} דקות</p>
            </div>
            {/* price */}
            <div className={styles.card} style={cardStyle}>
              <h3>מחיר</h3>
              <p style={textStyle}>₪{price}</p>
            </div>
          </div>

          <div className={styles.comment}>
            <input
              type="text"
              placeholder="יש לך הערות לבעל העסק?"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />

            {serviceDetails?.purchasable && (
              <div className={styles.amountToPayText}>
                <p>על מנת לקבוע יש לבצע תשלום מראש לעסק</p>
              </div>
            )}

            {/* IF 'purchasable' IS TRUE */}
            {serviceDetails?.purchasable ? (
              <button onClick={() => setIsPaymentModalOpen(true)}>
                ביצוע תשלום
              </button>
            ) : (
              <button onClick={handleClick}>
                {loading ? <Spinner /> : "אישור וקביעת התור"}
              </button>
            )}
          </div>

          <p className={styles.policy} onClick={() => setPolicyModalOpen(true)}>
            מדיניות ביטולים
          </p>

          <DownloadApp type={2} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateAppointmentModal;
