import { createContext, useState } from "react";
import AppRoutes from "./routes/routes";
import TagManager from "react-gtm-module";
import { colorPalette } from "./utils/theme";
import { useEffect } from "react";

export const ThemeContext = createContext();

const tagManagerArgs = {
  gtmId: "G-SR6J9ZESLG",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  window.dataLayer.push({
    event: "pageview",
  });

  const [color, setColor] = useState(null);

  let colorFromLocalStorage = localStorage.getItem("color");
  let disableUserBookings = localStorage.getItem("disableUserBookings");
  colorFromLocalStorage = JSON.parse(colorFromLocalStorage);

  useEffect(() => {
    if (colorFromLocalStorage) {
      let findColor = colorPalette.find(
        (i) =>
          i.mainColor?.toLowerCase() === colorFromLocalStorage?.toLowerCase()
      );
      setColor(findColor);
    } else {
      setColor(colorPalette[0]);
    }
  }, []);

  return (
    <ThemeContext.Provider value={color}>
      {color && <AppRoutes />}
    </ThemeContext.Provider>
  );
};

export default App;
