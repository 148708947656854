import { format } from "date-fns";

export const hebrewDay = (dateValue) => {
    let dayIndex = format(new Date(dateValue), "i");

    if (dayIndex === "1") {
        return "שני";
    } else if (dayIndex === "2") {
        return "שלישי";
    } else if (dayIndex === "3") {
        return "רביעי";
    } else if (dayIndex === "4") {
        return "חמישי";
    } else if (dayIndex === "5") {
        return "שישי";
    } else if (dayIndex === "6") {
        return "שבת";
    } else if (dayIndex === "7") {
        return "ראשון";
    }
};