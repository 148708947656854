import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../App";
import { toast } from "react-toastify";
import { paths } from "../../routes/paths";
import AUTH from "../../api/services/AUTH";
import Spinner from "../../components/Spinner";

const UpdateProfileCard = ({ styles }) => {
  const color = useContext(ThemeContext)

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let isClicked = localStorage.getItem("isClicked");

  let businessCode = localStorage.getItem("business_code");

  let userID = localStorage.getItem("user_id");

  // update profile
  const updateProfile = async () => {
    const payload = {
      _id: JSON.parse(userID),
      name,
      first_time: true,
      businessId: JSON.parse(businessCode),
    };

    setLoading(true);
    try {
      const res = await AUTH.update_profile(payload);
      console.log(res, "UPDATE RESPONSE");

      if (res.data.code === 200) {
        toast.success("ההרשמה בוצעה בהצלחה");
        // if (isClicked) {
        //   navigate(paths.appointment);
        //   localStorage.removeItem("isClicked");
        //   localStorage.setItem("openPopup", true);
        // } else {
          navigate(paths.business(JSON.parse(businessCode)));
          localStorage.setItem("openPopup", true);
        // }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.card}>
      <h2 style={{ marginBottom: "10px" }}>הרשמה מהירה</h2>

      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="שם מלא"
      />

      <button onClick={updateProfile} disabled={!name} style={{ backgroundColor: color?.mainColor }}>
        {loading ? <Spinner /> : "סיום הרשמה"}
      </button>
    </div>
  );
};

export default UpdateProfileCard;
