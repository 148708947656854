export const colorPalette = [
    {
        mainColor: "#d00254",
        subColor: "#DE146826"
    },
    {
        mainColor: "#007bff",
        subColor: "#007bff26"
    },
    {
        mainColor: "#e273AA",
        subColor: "#e273AA26"
    },
    {
        mainColor: "#111827",
        subColor: "#11182726"
    },
    {
        mainColor: "#28a745",
        subColor: "#28a74526"
    },
    {
        mainColor: "#c82333",
        subColor: "#c8233326"
    },
    {
        mainColor: "#fd7e14",
        subColor: "#fd7e1426"
    },
    {
        mainColor: "#9932cc",
        subColor: "#9932cc26"
    },
    {
        mainColor: "#884a39",
        subColor: "#884a3926"
    },
]