import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../App";
import Lottie from "lottie-react";
import BUSINESS from "../api/services/BUSINESS";
import popupAnimation from "../lottie/modal.json";
import styles from "../styles/notice.module.scss";

const Notice = ({ setIsOpen, popupHTML }) => {
  const color = useContext(ThemeContext);
  const [extractedText, setExtractedText] = useState(null);

  useEffect(() => {
    if (popupHTML) {
      const html = document.querySelector("#targetHtml");
      const spanElements = html.getElementsByTagName("span");
      const span = spanElements[0];
      const text = span.textContent;
      setExtractedText(text);
    }
  }, popupHTML);

  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.backdrop}
        onClick={() => {
          setIsOpen(false);
          localStorage.removeItem("openPopup");
        }}
      ></div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.lottie}>
            <Lottie
              animationData={popupAnimation}
              loop
              autoplay
              style={{ height: 60, width: 60, background: "transparent" }}
            />
          </div>
          <div className={styles.text}>
            <h1>הודעה ללקוחות</h1>
            {extractedText && <span>{extractedText}</span>}
          </div>
          <a
            onClick={() => {
              setIsOpen(false);
              localStorage.removeItem("openPopup");
            }}
            style={{ backgroundColor: color.mainColor, border: "none" }}
          >
            מאשר/ת קריאה
          </a>

          {popupHTML && (
            <div
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{ __html: popupHTML }}
              id="targetHtml"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Notice;
