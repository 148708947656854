import { useState, useContext } from "react";
import { generateProfileImgUrl } from "../../utils/generateProfileImgUrl";
import { ThemeContext } from "../../App";
import styles from "../../styles/employeesSection.module.scss";

const Employees = ({
  employees,
  currentEmployee,
  setCurrentEmployee,
  servicesRef,
}) => {
  
  if (!employees) return null;

  // IF ONE EMPLOYEE THEN HIDE
  if (employees.length === 1) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h2>בחירת עובד/ת</h2>
      <p className={styles.mainText}>ניתן לבחור עובד אחד בלבד</p>

      <div className={styles.employees}>
        {employees?.map((i) => (
          <EmployeeCard
            key={i.id}
            id={i.id}
            currentEmployee={currentEmployee}
            img={generateProfileImgUrl(i.profile)}
            name={i.name}
            handleClick={() => {
              setCurrentEmployee(i);
              setTimeout(() => {
                servicesRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Employees;

// EMPLOYEE CARD
export const EmployeeCard = ({
  id,
  currentEmployee,
  img,
  name,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  let color = useContext(ThemeContext);

  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        border:
          currentEmployee?.id === id
            ? `1px solid ${color.mainColor}`
            : isHovered === id
            ? `1px solid ${color.mainColor}`
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      <img src={img} alt={name} />
      <p
        style={{
          color:
            currentEmployee?.id === id
              ? color.mainColor
              : isHovered === id
              ? color.mainColor
              : "",
        }}
      >
        {name}
      </p>
    </div>
  );
};
