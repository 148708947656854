export const AddressIcon = ({ fill }) => {
  return (
    <svg
      fill={fill}
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 368.666 368.666"
    >
      <g id="XMLID_20_">
        <g>
          <g>
            <path
              d="M184.333,0C102.011,0,35.037,66.974,35.037,149.297c0,33.968,11.132,65.959,32.193,92.515
            C94.5,276.195,173.8,357.833,177.164,361.291l7.169,7.375l7.17-7.374c3.363-3.459,82.688-85.116,109.963-119.51
            c21.042-26.534,32.164-58.515,32.164-92.485C333.63,66.974,266.656,0,184.333,0z M285.797,229.355
            c-21.957,27.687-80.921,89.278-101.463,110.581c-20.54-21.302-79.482-82.875-101.434-110.552
            C64.67,206.4,55.037,178.707,55.037,149.297C55.037,78.002,113.039,20,184.333,20S313.63,78.002,313.63,149.297
            C313.63,178.708,304.005,206.392,285.797,229.355z"
            />
            <path
              d="M184.333,59.265c-48.729,0-88.374,39.645-88.374,88.374s39.644,88.374,88.374,88.374s88.374-39.644,88.374-88.374
            C272.708,98.909,233.063,59.265,184.333,59.265z M184.333,216.013c-37.701,0-68.374-30.672-68.374-68.374
            c0-37.702,30.672-68.374,68.374-68.374c37.702,0,68.374,30.672,68.374,68.374C252.708,185.34,222.034,216.013,184.333,216.013z"
            />
            <polygon points="180.672,154.314 155.56,134.334 143.107,149.986 183.797,182.357 227.13,128.423 211.537,115.897 			" />
          </g>
        </g>
      </g>
    </svg>
  );
};
