import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import isValidIsraeliPhoneNumber from "../../utils/isValidIsraeliPhoneNumber";
import AUTH from "../../api/services/AUTH";
import { useContext } from "react";
import { ThemeContext } from "../../App";

export const LoginCard = ({
  styles,
  phone,
  setPhone,
  loading,
  setLoading,
  setStep,
}) => {

  const color = useContext(ThemeContext)

  const handleLogin = async () => {
    if (phone.trim()) {
      setLoading(true);
      try {
        const payload = {
          phone,
          countryCode: "91",
        };

        const res = await AUTH.login(payload);
        if (res.status === 201) {
          // alert(res.data.otp);
          setStep(1);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    } else {
      alert("נא להכניס טלפון תקין");
    }
  };

  return (
    <div className={styles.card}>
      <h2>התחברות</h2>
      <p>אנא הזן את מספר הטלפון שלך</p>
      <input
        type="number"
        value={phone}
        placeholder="טלפון"
        onChange={(e) => setPhone(e.target.value)}
      />
      <button
        onClick={handleLogin}
        disabled={!isValidIsraeliPhoneNumber(phone)}
        style={{ backgroundColor: color?.mainColor }}
      >
        {loading ? <Spinner /> : "כניסה"}
      </button>
    </div>
  );
};
