import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../App";
import { paths } from "../../routes/paths";
import USER from "../../api/services/USER";

export const Action = ({
  styles,
  addBusinessSuccess,
  setAddBusinessSuccess,
  businessID,
  isRegistrationPending,
  employees,
  setOpenAddBusinessModal,
  setPendingModal,
}) => {
  let color = useContext(ThemeContext);

  const navigate = useNavigate();

  const [doesBusinessExist, setDoesBusinessExist] = useState(null);

  let token = localStorage.getItem("token");
  let disableUserBookings = localStorage.getItem("disableUserBookings");

  disableUserBookings =
    disableUserBookings === undefined ||
    disableUserBookings === null ||
    disableUserBookings === "undefined" ||
    disableUserBookings === "null"
      ? false
      : JSON.parse(disableUserBookings);

  // FETCH BUSINESSES LIST
  useEffect(() => {
    setAddBusinessSuccess(false);
    if (token) {
      const fetchData = async () => {
        try {
          const res = await USER.get_businesses_list();

          let businesses = res.user.map((i) =>
            i.businessId ? i.businessId.businessId : null
          );
          businesses = businesses.filter((i) => i !== null);

          let doesExist = businesses.includes(businessID);

          if (doesExist) {
            setDoesBusinessExist("yes");
          } else {
            setDoesBusinessExist("no");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [token, addBusinessSuccess]);

  // REDIRECT TO LOGIN IF NOT LOGGED IN
  const redirectToLogin = () => {
    localStorage.setItem("business_code", JSON.stringify(businessID));
    localStorage.setItem("isClicked", true);
    navigate(paths.login);
  };

  const handleScheduleBtnClick = () => {
    navigate(paths.appointment);
  };

  if (disableUserBookings) {
    return null;
  }

  // if (!disableUserBookings) {
  // IF USER NOT LOGGED IN
  if (!token) {
    return (
      <button
        className={styles.actionBtn}
        onClick={redirectToLogin}
        style={{ backgroundColor: color?.mainColor }}
      >
        אני רוצה לקבוע תור עכשיו
      </button>
    );
  }

  // IF USER LOGGED IN AND BUSINESS IS NOT ADDED
  if (
    token &&
    doesBusinessExist &&
    doesBusinessExist === "no" &&
    !disableUserBookings
  ) {
    return (
      <button
        className={styles.actionBtn}
        onClick={() => setOpenAddBusinessModal(true)}
        style={{ backgroundColor: color?.mainColor }}
      >
        אני רוצה לקבוע תור עכשיו
      </button>
    );
  }

  // IF USER LOGGED IN, BUSINESS IS ADDED AND REGISTRATION IS PENDING
  if (
    token &&
    doesBusinessExist &&
    doesBusinessExist === "yes" &&
    isRegistrationPending
  ) {
    return (
      <button
        className={styles.actionBtn}
        onClick={() => setPendingModal(true)}
        style={{ backgroundColor: color?.mainColor }}
      >
        הרשמתך ממתינה לאישור
      </button>
    );
  }

  // IF USER LOGGED IN, BUSINESS IS ADDED AND REGISTRATION IS NOT PENDING, THEN SCHEDULE APPOINTMENT
  if (
    token &&
    doesBusinessExist &&
    doesBusinessExist === "yes" &&
    !isRegistrationPending &&
    !disableUserBookings
  ) {
    return (
      <button
        className={styles.actionBtn}
        onClick={handleScheduleBtnClick}
        style={{ backgroundColor: color?.mainColor }}
      >
        אני רוצה לקבוע תור עכשיו
      </button>
    );
  }
  // }
};
