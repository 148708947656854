import { useContext } from "react";
import { ThemeContext } from "../../App";
import { Caution } from "../../svgs/caution";
import Modal from "../../components/Modal";
import styles from "../../styles/confirmModal.module.scss";

export const PolicyModal = ({ setIsOpen }) => {
  const color = useContext(ThemeContext);

  let cancellationText = localStorage.getItem("cancellationText");
  cancellationText = JSON.parse(cancellationText)

  return (
    <Modal>
      <div className={styles.container}>
        <Caution fill={color.mainColor} />
        <h2 style={{ marginBottom: "20px" }}>מדיניות ביטולים</h2>
        <p>{cancellationText}</p>
        <div className={styles.btns} style={{ justifyContent: "center" }}>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              border: `1px solid ${color.mainColor}`,
              color: color.mainColor,
              background: color.subColor,
            }}
          >
            יציאה
          </button>
        </div>
      </div>
    </Modal>
  );
};
