import { useEffect } from "react";
import Card from "../containers/_myAppointments/Card";
import Layout from "../layout/layout";
import styles from "../styles/myAppointments.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

const MyAppointments = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("token");

  // if not token, then redirect to login
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token, navigate]);

  return (
    <Layout>
      <div className={styles.container}>
        <Card />
      </div>
    </Layout>
  )
}

export default MyAppointments