import axios from "axios";
import { BASE_URL } from "../config";

let axiosInstance = axios.create({
    baseURL: BASE_URL,
});

let axiosInstance2 = axios.create({
    baseURL: "https://restcountries.com/v2",
});

function configAxios() {
    axiosInstance = axios.create({
        baseURL: BASE_URL,
    });
}

function getHeaders() {
    const token = localStorage.getItem("token");
    return {
        Authorization: token && JSON.parse(token),
    };
}

export function post(url, data, headers = true) {
    configAxios();

    return axiosInstance({
        method: "POST",
        url,
        data,
        headers: headers ? getHeaders() : {},
    });
}

// delete is a reserved name
export function del(url) {
    configAxios();
    return axiosInstance({
        method: "DELETE",
        url,
        headers: getHeaders(),
    });
}

export async function get(url) {
    const { data } = await axiosInstance({
        method: "GET",
        url,
        headers: getHeaders(),
    });
    return data;
}

export async function get2(url) {
    const { data } = await axiosInstance2({
        method: "GET",
        url,
        headers: getHeaders(),
    });
    return data;
}

export function put(url, data) {
    configAxios();
    return axiosInstance({
        method: "PUT",
        url,
        data,
        headers: getHeaders(),
    });
}
