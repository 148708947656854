import { useContext } from "react";
import { ThemeContext } from "../../App";
import Modal from "../../components/Modal";
import { Caution } from "../../svgs/caution";
import styles from "../../styles/pendingModal.module.scss";

export const PendingModal = ({ setPendingModal }) => {
  const color = useContext(ThemeContext);
  return (
    <Modal>
      <div className={styles.noBusinessModalContainer}>
        <Caution fill={color.mainColor} />
        <h2>הרשמתך בהמתנה</h2>
        <p>אנא פנו לבעל העסק להמשך קביעת תור</p>
        <button
          onClick={() => setPendingModal(false)}
          style={{ backgroundColor: color.mainColor }}
        >
          תודה והמשך
        </button>
      </div>
    </Modal>
  );
};
