import { useState } from "react";
import Layout from "../layout/layout";
import { LoginCard } from "../containers/_auth/loginCard";
import { OTPCard } from "../containers/_auth/OTPCard";
import UpdateProfileCard from "../containers/_auth/updateProfileCard";
import styles from "../styles/auth.module.scss";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  return (
    <Layout>
      <div className={styles.container}>
        {step === 0 ? (
          <LoginCard
            styles={styles}
            phone={phone}
            setPhone={setPhone}
            loading={loading}
            setLoading={setLoading}
            setStep={setStep}
          />
        ) : step === 1 ? (
          <OTPCard
            styles={styles}
            OTP={OTP}
            setOTP={setOTP}
            phone={phone}
            loading={loading}
            setLoading={setLoading}
            setStep={setStep}
          />
        ) : (
          <UpdateProfileCard styles={styles} />
        )}
      </div>
    </Layout>
  );
};

export default Login;
