import { useEffect, useState } from "react";
import { format } from "date-fns";
import Modal from "../../components/Modal";
import { hebrewDay } from "../../utils/getHebrewDay";
import { hebrewMonth } from "../../utils/getHebrewMonth";
import styles from "../../styles/detailsModal.module.scss";

const DetailsModal = ({ setIsOpen, data, setConfirm }) => {
  let businessCancellationPeriod = localStorage.getItem(
    "business_cancellation_period"
  );

  const [canCancelled, setCanCancelled] = useState(true);

  // check if user can cancel appointment
  useEffect(() => {
    // appointment date
    let appointmentDate = new Date(data.data.date);

    appointmentDate = appointmentDate.setHours(0, 0, 0);

    // Add timeslots to the start date (hours)
    appointmentDate = new Date(appointmentDate).setHours(
      new Date(appointmentDate).getHours() + data.data.timeSlot.slice(0, 2)
    );

    // Add timeslots to the start date (minutes)
    appointmentDate = new Date(appointmentDate).setMinutes(
      new Date(appointmentDate).getMinutes() +
        data.data.timeSlot.substring(data.data.timeSlot.length - 2)
    );

    // current date in minutes
    let currDateInMins = Math.floor(new Date().getTime() / 1000 / 60);

    // appointment date in minutes
    let appointmentDateInMins = Math.floor(
      new Date(appointmentDate).getTime() / 1000 / 60
    );

    // minutes left in the scheduled appointment
    let minutesLeft = appointmentDateInMins - currDateInMins;

    // if the remaining minutes are greater than the cancellation minutes then cancel the appointment
    if (minutesLeft < JSON.parse(businessCancellationPeriod)) {
      setCanCancelled(false);
    } else {
      setCanCancelled(true);
    }
  }, []);

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className={styles.container}>
        <span onClick={() => setIsOpen(false)}>&times;</span>
        <h2>פרטי התור</h2>

        {/* EMPLOYEE */}
        <div className={styles.employee}>
          <img src="/assets/user-icon.svg" alt="user-icon" />
          <div className={styles.text}>
            <h3>{data.data.employeeId.name}</h3>
            <p>{data.data.employeeId.phone} 972+</p>
          </div>
        </div>

        <hr />

        {/* DATE AND TIME */}
        <div className={styles.row}>
          <img src="/assets/calendar-icon.svg" alt="calendar-icon" />
          <div className={styles.text}>
            <h4>{data.data.timeSlot}</h4>
            <p>
              {hebrewDay(data.data.date)}{" "}
              {format(new Date(data.data.date), "dd")} ל
              {hebrewMonth(data.data.date)}
            </p>
          </div>
        </div>

        {/* SERVICE */}
        <div className={styles.row}>
          <img src="/assets/suitcase-icon.svg" alt="suitcase-icon" />
          <div className={styles.text}>
            <h4>{data.service_name}</h4>
            <p>₪{data.service_price}</p>
          </div>
        </div>

        {/* COMMENT */}
        {data.data.comment && (
          <div className={styles.row}>
            <img
              src="/assets/comment-icon.svg"
              alt="comment-icon"
              className={styles.commentIcon}
            />
            <div className={styles.text}>
              <h4>{data.data.comment}</h4>
            </div>
          </div>
        )}

        {/* STATUS */}
        <div className={styles.row}>
          {data.data.isConfirmed === "CONFIRMED" ? (
            <div className={`${styles.approved} ${styles.status}`}>
              <p>אישרת הגעה</p>
            </div>
          ) : data.data.isConfirmed === "DECLINED" ? (
            <div className={`${styles.declined} ${styles.status}`}>
              <p>לא אישרת הגעה</p>
            </div>
          ) : (
            <div className={`${styles.pending} ${styles.status}`}>
              <p>ממתין לאישור</p>
            </div>
          )}
        </div>

        {/* CANCEL */}
        <div className={`${styles.row} ${styles.lastRow}`}>
          {canCancelled && new Date(data.data.date) > new Date() && (
            <p
              className={styles.cancel}
              onClick={() => setConfirm(data.data._id)}
            >
              ביטול תור
            </p>
          )}

          {!canCancelled && (
            <p className={styles.cancel}>
              זמן ביטול התור עבר, לצורך ביטול או שינוי נא ליצור קשר עם בעל העסק.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DetailsModal;
