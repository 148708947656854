import { get, post } from "../client";
import {
  ADD_TO_WAITING_LIST,
  BUSINESS_DETAILS,
  BUSINESS_EMPLOYEES,
  BUSINESS_SCHEDULE,
  EMPLOYEE_SERVICES,
  EMPLOYEE_TIMESLOTS,
  FETCH_POPUP,
  SERVICE_DETAILS,
  PUBLIC_BUSINESS_EMPLOYEES,
  BUSINESS_DETAILS_PRIVATE,
  EMPLOYEE_SERVICES_FLOW_2
} from "../routes";

export default {
  details: async (id) => await get(BUSINESS_DETAILS(id)),

  details_private: async (id) => await get(BUSINESS_DETAILS_PRIVATE(id)),

  schedule: async (id) => await get(BUSINESS_SCHEDULE(id)),

  employees: async (id) => await get(BUSINESS_EMPLOYEES(id)),

  public_employees: async (id) => await get(PUBLIC_BUSINESS_EMPLOYEES(id)),

  employee_services: async (id) => await get(EMPLOYEE_SERVICES(id)),

  employee_services_2: async (data) => await post(EMPLOYEE_SERVICES_FLOW_2, data),

  employee_timeslots: async (data) => await post(EMPLOYEE_TIMESLOTS, data),

  service_details: async (id) => await get(SERVICE_DETAILS(id)),

  fetch_popup: async (data) => await post(FETCH_POPUP, data),

  add_to_waiting_list: async (data) => await post(ADD_TO_WAITING_LIST, data),
};
