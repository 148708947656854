import styles from "../../styles/profileSection.module.scss";

const Profile = ({ coverImg, profileImg, businessName, description }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={coverImg} alt="cover" className={styles.cover} />
        <img src={profileImg} alt="profile" className={styles.profile} />
      </div>

      <h3>{businessName}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Profile;
