import { IMAGE_BASE_URL } from "../../config";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import styles from "../../styles/gallerySection.module.scss";

const Gallery = ({ images, setFullModeImg }) => {
  if (images && images.length === 0) {
    return null;
  }

  const DEFAULT_IMAGE_URL =
    "https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg";
  return (
    <div className={styles.container}>
      <h2>גלריית תמונות</h2>
      <p></p>

      <div className={styles.images}>
        <Flickity
          options={{
            prevNextButtons: false,
            pageDots: true,
            contain: true,
            wrapAround: true,
            groupCells: true,
            cellAlign: "center",
            initialIndex: 2,
          }}
          disableImagesLoaded={false}
          reloadOnUpdate
        >
          {images?.map((i, idx) => (
            <img
              src={
                i
                  ? i.includes("data:image") ||
                    i.includes("https://api.tor.digital/")
                    ? i
                    : IMAGE_BASE_URL + "/v1/uploads/" + i
                  : DEFAULT_IMAGE_URL
              }
              alt="gallery-pic"
              key={idx}
              onClick={() =>
                setFullModeImg(
                  i
                    ? i.includes("data:image") ||
                      i.includes("https://api.tor.digital/")
                      ? i
                      : IMAGE_BASE_URL + "/v1/uploads/" + i
                    : DEFAULT_IMAGE_URL
                )
              }
            />
          ))}
          {images?.map((i, idx) => (
            <img
              src={
                i
                  ? i.includes("data:image") ||
                    i.includes("https://api.tor.digital/")
                    ? i
                    : IMAGE_BASE_URL + "/v1/uploads/" + i
                  : DEFAULT_IMAGE_URL
              }
              alt="gallery-pic"
              key={idx}
              onClick={() =>
                setFullModeImg(
                  i
                    ? i.includes("data:image") ||
                      i.includes("https://api.tor.digital/")
                      ? i
                      : IMAGE_BASE_URL + "/v1/uploads/" + i
                    : DEFAULT_IMAGE_URL
                )
              }
            />
          ))}
        </Flickity>
      </div>
    </div>
  );
};

export default Gallery;
