import styles from "../styles/fullModeImage.module.scss";

const FullModeImage = ({ img, setIsOpen }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.backdrop} onClick={() => setIsOpen(null)}></div>
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(null)}>&times;</span>
        <img src={img} alt={"full-screen-pic"} />
      </div>
    </div>
  );
};

export default FullModeImage;
