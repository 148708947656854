import React from "react";
import Modal from "../../components/Modal";
import USER from "../../api/services/USER";
import { toast } from "react-toastify";
import styles from "../../styles/addBusiness.module.scss";

export const AddBusiness = ({
  businessId,
  setOpenAddBusinessModal,
  setAddBusinessSuccess,
}) => {
  // ADD BUSINESS
  const addBusiness = async () => {
    const payload = { businessId };
    try {
      const res = await USER.add_business(payload);
      toast.success(res.data.message);
      setOpenAddBusinessModal(false);
      setAddBusinessSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal>
      <div className={styles.noBusinessModalContainer}>
        <h2>הרשמה לעסק</h2>
        <p>
          נראה שאת/ה לא רשומ/ה לעסק.
          <br />
          לחצו להרשמה כלקוח לעסק
        </p>
        <button onClick={addBusiness}>הרשמה</button>
      </div>
    </Modal>
  );
};
