// AUTH
const LOGIN = `/users/create`;

const VERIFY = `/users/otp_verify`;

const UPDATE_PROFILE = `/users/update_profile`;

// BUSINESS
const BUSINESS_DETAILS = (code) => `/business/business_details_public/${code}`;

const BUSINESS_DETAILS_PRIVATE = (code) => `/business/business_details/${code}`;

const BUSINESS_SCHEDULE = (id) =>
  `/schedule/check_schedule_user_public?employeeId=${id}`;

const BUSINESS_EMPLOYEES = (id) => `/business/get_employees_user/${id}`;

const PUBLIC_BUSINESS_EMPLOYEES = (id) =>
  `/business/get_employees_public/${id}`;

const EMPLOYEE_SERVICES = (id) => `/services/get_services_user/${id}`;

const EMPLOYEE_SERVICES_FLOW_2 = `/services/get_all_business_services_for_user`;

const BUSINESS_DESC = (id) => `business/business_details/${id}`;

const EMPLOYEE_TIMESLOTS = "/schedule/user/get_timeslots";

const SERVICE_DETAILS = (id) => `/services/get_details_user/${id}`;

const FETCH_POPUP = `/users/popUpFetch`;

const ADD_TO_WAITING_LIST = `/appointment/add_to_waiting_list`;

// USER
const GET_PROFILE = "/users/profile";

const UPDATE_IMAGE = "/users/updateImage";

const DELETE_ACCOUNT = "/users/delete_user";

const SCHEDULE_APPOINTMENT = "/appointment/appointment_create";

const SCHEDULE_APPOINTMENT_WITH_PAYMENT = "/users/createAppointmentWithPayment";

const CANCEL_APPOINTMENT = "/appointment/appointment_cancel";

const USER_APPOINTMENTS = "/appointment/user_appointments";

const CONFIRM_APPOINTMENT = "/appointment/confirm/";

const BUSINESSES_LIST = "/users/get_user_business";

const ADD_BUSINESS = "/users/add_user_business";

export {
  LOGIN,
  VERIFY,
  UPDATE_PROFILE,
  UPDATE_IMAGE,
  DELETE_ACCOUNT,
  BUSINESS_DETAILS,
  BUSINESS_DETAILS_PRIVATE,
  BUSINESS_SCHEDULE,
  BUSINESS_EMPLOYEES,
  BUSINESS_DESC,
  EMPLOYEE_SERVICES,
  EMPLOYEE_SERVICES_FLOW_2,
  EMPLOYEE_TIMESLOTS,
  PUBLIC_BUSINESS_EMPLOYEES,
  SERVICE_DETAILS,
  FETCH_POPUP,
  GET_PROFILE,
  SCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT_WITH_PAYMENT,
  CANCEL_APPOINTMENT,
  USER_APPOINTMENTS,
  CONFIRM_APPOINTMENT,
  ADD_TO_WAITING_LIST,
  BUSINESSES_LIST,
  ADD_BUSINESS,
};
