import { useContext } from "react";
import { ThemeContext } from "../../App";
import Modal from "../../components/Modal";
import DownloadApp from "../../components/downloadApp";
import styles from "../../styles/waitingListModal.module.scss";

const WaitingListModal = ({ type, setWaitingListModal, addToWaitingList }) => {
  let color = useContext(ThemeContext)

  const buttonOneStyle = {
    border: `1px solid ${color.mainColor}`,
    backgroundColor: color.mainColor,
  }

  const buttonTwoStyle = {
    border: `1px solid ${color.mainColor}`,
    backgroundColor: color.subColor,
    color: color.mainColor,
    width:'40%'
  }

  return (
    <Modal setIsOpen={setWaitingListModal}>
      <div className={styles.modalContainer}>
        <DownloadApp type={1} />
        <hr />
        <div className={styles.addToWaitingList}>
          <h4>הרשמה לרשימת המתנה</h4>
          <div className={styles.btns}>
            <button onClick={addToWaitingList} style={buttonOneStyle}>
              {type === "time" ? "הרשמה להמתנה" : "הרשמה לרשימת המתנה"}
            </button>
            <button  onClick={() => setWaitingListModal(false)} style={buttonTwoStyle}>
              יציאה וביטול
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WaitingListModal;
