export const paths = {
  login: "/auth/login/",

  business: (id) => `/${id}`,
  
  appointment: "/create-appointment",
  
  my_appointments: "/my-appointments/",

  profile: "/profile/",
  
  main: "/app/business/",
  
  schedule_appointment: "/app/schedule-appointment/",

  select_employee: (id) => `/app/select-employee/${id}`,

  select_service: (id) => `/app/select-service/${id}`,

  select_date: (id) => `/app/select-date/${id}`,

  confirmation: "/confirmation/:appointmentId"
};
