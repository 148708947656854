import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ThemeContext } from "../App";
import Layout from "../layout/layout";
import Employees from "../containers/_appointment/Employees";
import Services from "../containers/_appointment/Services";
import SelectTime from "../containers/_appointment/SelectTime";
import BUSINESS from "../api/services/BUSINESS";
import WaitingListModal from "../containers/_appointment/waitingListModal";
import CreateAppointmentModal from "../containers/_appointment/createAppointmentModal";
import USER from "../api/services/USER";
import styles from "../styles/appointment.module.scss";
import { PolicyModal } from "../containers/_appointment/policyModal";
import PaymentModal from "../containers/_appointment/paymentModal";
import { Helmet } from "react-helmet";

const Appointment = () => {
  const navigate = useNavigate();

  let color = useContext(ThemeContext);

  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // GET BUSINESS CODE FROM THE LOCAL STORAGE
  let businessCode = localStorage.getItem("business_code");
  businessCode = JSON.parse(businessCode);

  // GET BUSINESS NAME FROM THE LOCAL STORAGE
  let businessName = localStorage.getItem("business_name");
  businessName = JSON.parse(businessName);

  // GET USER ID FROM THE LOCAL STORAGE
  let userId = localStorage.getItem("user_id");
  userId = JSON.parse(userId);

  // IF THERE IS NO BUSINESS CODE THEN REDIRECT TO LOGIN PAGE
  useEffect(() => {
    if (!businessCode) {
      navigate(paths.login);
    }
  }, [businessCode]);

  // FETCH EMPLOYEES
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await BUSINESS.employees(businessCode);

        if (res.data.length === 0) {
          setEmployees([]);
        } else if (res.data.length === 1) {
          setEmployees(res.data);
          setCurrentEmployee(res.data[0]);
        } else {
          setEmployees(res.data);
        }
      } catch (error) {
        console.log(error);
        if (
          error.response.status === 401 ||
          error.response.data.message === "jwt malformed" ||
          error.response.data.message === "Invalid Token"
        ) {
          localStorage.removeItem("token");
          navigate(paths.login);
        }
      }
    };

    fetchData();
  }, []);

  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [timeslot, setTimeslot] = useState(null);
  const [dateValue, onChangeDateValue] = useState(null);
  const [comment, setComment] = useState("");

  const [waitingListModalSlot, setWaitingListModalSlot] = useState(false);
  const [waitingListModalDay, setWaitingListModalDay] = useState(false);

  const [isCreateAppointmentModalOpen, setIsCreateAppointmentModalOpen] =
    useState(false);

  const [createAppointmentLoading, setCreateAppointmentLoading] =
    useState(false);

  const [
    createAppointmentWithPaymentLoading,
    setCreateAppointmentWithPaymentLoading,
  ] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [policyModalOpen, setPolicyModalOpen] = useState(false);

  const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);

  let flow = localStorage.getItem("flow");
  flow = JSON.parse(flow);

  // add to waiting list
  const addToWaitingList = async () => {
    const payload = {
      employeeId: currentEmployee.id,
      userId: userId,
      serviceId: currentService._id,
      date: format(new Date(dateValue), "yyyy-MM-dd"),
      type: "TIME",
      timeslot: timeslot,
    };

    try {
      const res = await BUSINESS.add_to_waiting_list(payload);
      toast.success("נרשמת בהצלחה לרשימת ההמתנה");
      setWaitingListModalSlot(false);
    } catch (error) {
      console.log(error);
      toast.error("את/ה כבר נמצא ברשימת ההמתנה");
      setWaitingListModalSlot(false);
    }
  };

  // add to waiting list DAY
  const addToWaitingListDay = async () => {
    const payload = {
      employeeId: currentEmployee.id,
      userId: userId,
      serviceId: currentService._id,
      date: format(new Date(dateValue), "yyyy-MM-dd"),
      type: "DAY",
    };

    try {
      const res = await BUSINESS.add_to_waiting_list(payload);
      toast.success("נרשמת בהצלחה לרשימת ההמתנה");
      setWaitingListModalDay(false);
    } catch (error) {
      console.log(error);
      toast.error("לא הצלחנו לרשום אותך");
      setWaitingListModalDay(false);
    }
  };

  // CREATE APPOINTMENT
  const handleCreateAppointment = async () => {
    let payload = {
      employeeId: currentEmployee.id,
      userId: userId,
      serviceId: currentService._id,
      businessId: businessCode,
      date: format(new Date(dateValue), "yyyy-MM-dd"),
      timeSlot: timeslot,
    };

    if (comment) {
      payload.comment = comment;
    }

    setCreateAppointmentLoading(true);
    try {
      const res = await USER.schedule_appointment(payload);
      setIsCreateAppointmentModalOpen(false);
      toast.success("התור נקבע בהצלחה! אנא המתינו..");
      navigate(paths.my_appointments);
    } catch (error) {
      console.log(error);
      if (
        error.response.data.message ===
        "You have already booked an appointment in this service."
      ) {
        toast.error("לא ניתן לקבוע יותר מתור אחד בעסק זה");
      } else {
        toast.error(error.response.data.message);
      }
      setIsCreateAppointmentModalOpen(false);
    }

    setCreateAppointmentLoading(false);
  };

  // CREATE APPOINTMENT WITH PAYMENT
  const handleCreateAppointmentWithPayment = async () => {
    const {
      cardNumber,
      holderId,
      companyId,
      fullName,
      cvv,
      exp,
      address,
      city,
      phone,
      email,
    } = paymentFormData;

    if (
      !cardNumber ||
      !fullName ||
      !holderId ||
      !companyId ||
      !cvv ||
      !exp ||
      !address ||
      !city ||
      !phone ||
      !email
    ) {
      setPaymentErrorMessage("יש למלא את כל השדות");
      return;
    }

    let payload = {
      employeeId: currentEmployee.id,
      userId: userId,
      serviceId: currentService._id,
      businessId: businessCode,
      date: format(new Date(dateValue), "yyyy-MM-dd"),
      timeSlot: timeslot,

      cvv: cvv,
      holderId: holderId,
      exp: exp,
      cardNumber: cardNumber,
      fullName: fullName,
      email: email,
      companyId: companyId,
      address: address,
      phone: phone,
    };

    setCreateAppointmentWithPaymentLoading(true);
    try {
      const res = await USER.schedule_appointment_with_payment(payload);
      setIsCreateAppointmentModalOpen(false);
      setIsPaymentModalOpen(false);
      toast.success("התור נקבע בהצלחה! אנא המתינו..");
      navigate(paths.my_appointments);

      console.log(res, "RESPONSE");
    } catch (error) {
      console.log(error);
      if (
        error.response.data.message ===
        "You have already booked an appointment in this service."
      ) {
        toast.error("לא ניתן לקבוע יותר מתור אחד בעסק זה");
      } else {
        toast.error(error.response.data.message);
      }
      setIsCreateAppointmentModalOpen(false);
      setIsPaymentModalOpen(false);
    }
    setCreateAppointmentWithPaymentLoading(false);
  };

  const selectTimeRef = useRef(null);
  const servicesRef = useRef(null);

  const [paymentFormData, setPaymentFormData] = useState({
    cvv: "",
    holderId: "",
    exp: "",
    cardNumber: "",
    fullName: "",
    email: "",
    companyId: "",
    address: "",
    city: "",
    phone: "",
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{businessName}</title>
      </Helmet>
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* IF FLOW === 1 THEN SHOW EMPLOYEES */}
            {flow === 1 && (
              <Employees
                employees={employees}
                currentEmployee={currentEmployee}
                setCurrentEmployee={setCurrentEmployee}
                currentService={currentService}
                servicesRef={servicesRef}
              />
            )}

            {/* IF FLOW === 1, THEN CHECK FOR THE CURRENT EMPLOYEE */}
            {flow === 1 ? (
              currentEmployee && (
                <Services
                  currentEmployee={currentEmployee}
                  currentService={currentService}
                  setCurrentService={setCurrentService}
                  selectTimeRef={selectTimeRef}
                  ref={servicesRef}
                />
              )
            ) : (
              // IF FLOW === 2

              <Services
                currentEmployee={currentEmployee}
                setCurrentEmployee={setCurrentEmployee}
                currentService={currentService}
                setCurrentService={setCurrentService}
                selectTimeRef={selectTimeRef}
                ref={servicesRef}
              />
            )}

            {currentService && (
              <SelectTime
                timeslot={timeslot}
                setTimeslot={setTimeslot}
                dateValue={dateValue}
                onChangeDateValue={onChangeDateValue}
                currentEmployee={currentEmployee}
                currentService={currentService}
                setWaitingListModalSlot={setWaitingListModalSlot}
                setWaitingListModalDay={setWaitingListModalDay}
                ref={selectTimeRef}
              />
            )}
            {currentEmployee && currentService && dateValue && timeslot && (
              <button
                className={styles.makeAppointmentBtn}
                onClick={() => setIsCreateAppointmentModalOpen(true)}
                style={{ backgroundColor: color.mainColor }}
              >
                קבעו לי תור
              </button>
            )}
          </div>

          {/* === MODALS === */}

          {/* WAITING LIST TIME */}
          {waitingListModalSlot && (
            <WaitingListModal
              type="time"
              setWaitingListModal={setWaitingListModalSlot}
              addToWaitingList={addToWaitingList}
            />
          )}
          {/* WAITING LIST DAY */}
          {waitingListModalDay && (
            <WaitingListModal
              type="day"
              setWaitingListModal={setWaitingListModalDay}
              addToWaitingList={addToWaitingListDay}
            />
          )}

          {/* CREATE APPOINTMENT */}
          {isCreateAppointmentModalOpen && (
            <CreateAppointmentModal
              setOpen={setIsCreateAppointmentModalOpen}
              employee={currentEmployee.name}
              service={currentService.name}
              serviceDetails={currentService}
              time={currentService.time}
              price={currentService.price}
              dateValue={dateValue}
              timeslot={timeslot}
              comment={comment}
              setComment={setComment}
              handleClick={handleCreateAppointment}
              loading={createAppointmentLoading}
              setLoading={setCreateAppointmentLoading}
              setPolicyModalOpen={setPolicyModalOpen}
              setIsPaymentModalOpen={setIsPaymentModalOpen}
            />
          )}

          {isPaymentModalOpen && (
            <PaymentModal
              setOpen={setIsPaymentModalOpen}
              paymentFormData={paymentFormData}
              setPaymentFormData={setPaymentFormData}
              service={currentService.name}
              price={currentService.price}
              dateValue={dateValue}
              loading={createAppointmentWithPaymentLoading}
              handleSubmit={handleCreateAppointmentWithPayment}
              paymentErrorMessage={paymentErrorMessage}
              setPaymentErrorMessage={setPaymentErrorMessage}
            />
          )}

          {/* POLICY MODAL */}
          {policyModalOpen && <PolicyModal setIsOpen={setPolicyModalOpen} />}
        </div>
      </Layout>
    </>
  );
};

export default Appointment;
