import { useState, useEffect } from "react";
import BUSINESS from "../../api/services/BUSINESS";
import Spinner from "../../components/Spinner";

export const Days = ({
  styles,
  currentEmployee,
  employees,
  setDoesBusinessHasSchedule,
}) => {
  const [businessSchedule, setBusinessSchedule] = useState(null);
  const [loading, setLoading] = useState(false);

  let flow = localStorage.getItem("flow");
  flow = JSON.parse(flow);

  // ====== FLOW 1 STARTS =======

  // FETCH BUSINESS SCHEDULE
  useEffect(() => {
    if (flow === 1) {
      setLoading(true);
      const fetchData = async () => {
        if (currentEmployee) {
          try {
            const business_schedule = await BUSINESS.schedule(currentEmployee);

            if (business_schedule?.data?.length === 0) {
              setBusinessSchedule([]);
              setDoesBusinessHasSchedule(false);
            } else {
              setDoesBusinessHasSchedule(true);
              const business_days = business_schedule?.data[0]?.days;

              // ADD MISSING DAYS =============
              // Define an array of all possible weekdays
              const allWeekdays = [
                {
                  name: "monday",
                },
                {
                  name: "tuesday",
                },
                {
                  name: "wednesday",
                },
                {
                  name: "thursday",
                },
                {
                  name: "friday",
                },
                {
                  name: "saturday",
                },
                {
                  name: "sunday",
                },
              ];

              // Use the concat() and filter() methods to add any missing days to the weekdays array
              const updatedWeekdays = business_days.concat(
                allWeekdays.filter(
                  (day) => !business_days.some((e) => e.name === day.name)
                )
              );

              // ADD MISSING DAYS END  ========

              // SORT DAYS ====================

              const map = {
                sunday: 1,
                monday: 2,
                tuesday: 3,
                wednesday: 4,
                thursday: 5,
                friday: 6,
                saturday: 7,
              };

              let sortedDays = updatedWeekdays.sort((a, b) => {
                return map[a.name] - map[b.name];
              });

              setBusinessSchedule(sortedDays);

              // SORT DAYS ====================
            }
          } catch (error) {
            console.log(error);
            setBusinessSchedule([]);
          }
        }
      };

      fetchData();
      setLoading(false);
    }
  }, [currentEmployee]);

  // ====== FLOW 1 STARTS =======

  // ---------------------------------------------

  // ====== FLOW 2 STARTS =======
  const [allSchedule, setAllSchedule] = useState([]);

  const [loopRan, setLoopRan] = useState(false);

  // FETCH ALL EMPLOYEES' SCHEDULE
  useEffect(() => {
    if (flow === 2) {
      setLoading(true);
      let employeesIds = employees?.map((i) => i.id);
      for (let index = 0; index < employeesIds?.length; index++) {
        const element = employeesIds[index];

        const fetchData = async () => {
          try {
            const business_schedule = await BUSINESS.schedule(element);
            if (business_schedule?.data?.length === 0) {
              setBusinessSchedule([]);
              setDoesBusinessHasSchedule(false);
            } else {
              setDoesBusinessHasSchedule(true);
              const business_days = business_schedule?.data[0]?.days;

              // ADD MISSING DAYS =============
              // Define an array of all possible weekdays
              const allWeekdays = [
                {
                  name: "monday",
                },
                {
                  name: "tuesday",
                },
                {
                  name: "wednesday",
                },
                {
                  name: "thursday",
                },
                {
                  name: "friday",
                },
                {
                  name: "saturday",
                },
                {
                  name: "sunday",
                },
              ];

              // Use the concat() and filter() methods to add any missing days to the weekdays array
              const updatedWeekdays = business_days.concat(
                allWeekdays.filter(
                  (day) => !business_days.some((e) => e.name === day.name)
                )
              );
              // ADD MISSING DAYS END  ========

              // SORT DAYS ====================

              const map = {
                sunday: 1,
                monday: 2,
                tuesday: 3,
                wednesday: 4,
                thursday: 5,
                friday: 6,
                saturday: 7,
              };

              let sortedDays = updatedWeekdays.sort((a, b) => {
                return map[a.name] - map[b.name];
              });

              setAllSchedule((prev) => [...prev, sortedDays]);

              // SORT DAYS ====================
            }
          } catch (error) {
            console.log(error);
            setBusinessSchedule([]);
          }
        };

        fetchData();
      }

      setLoopRan(true);

      setLoading(false);
    }
  }, [employees]);

  // MERGE ALL EMPLOYEES' SCHEDULE
  useEffect(() => {
    if (flow === 2) {
      if (allSchedule.length > 0) {
        // Create a new object to store the merged data
        const mergedObject = {};

        // Helper function to merge timing arrays
        function mergeTimingArrays(arr1, arr2) {
          return [...arr1, ...arr2];
        }

        for (const arrayItem of allSchedule) {
          for (const item of arrayItem) {
            if (!mergedObject[item.name]) {
              mergedObject[item.name] = {
                name: item.name,
                timing: item.timing && item.status ? item.timing : [],
                status: item.status,
              };
            } else {
              mergedObject[item.name].timing = mergeTimingArrays(
                mergedObject[item.name].timing,
                item.timing && item.status ? item.timing : []
              );
            }
          }
        }

        // Convert the merged object into an array
        const mergedArray = Object.values(mergedObject);

        // Function to check if two timing objects have the same start_time and end_time
        function areTimingObjectsDuplicate(timingObj1, timingObj2) {
          return (
            timingObj1.start_time === timingObj2.start_time &&
            timingObj1.end_time === timingObj2.end_time
          );
        }

        // Function to remove duplicate timing objects from an array
        function removeDuplicateTimingObjects(timingArray) {
          const uniqueTimingArray = timingArray.filter(
            (timingObj, index, arr) => {
              // Check if the current timing object is the first occurrence of its start_time and end_time
              return (
                arr.findIndex((obj) =>
                  areTimingObjectsDuplicate(obj, timingObj)
                ) === index
              );
            }
          );

          return uniqueTimingArray;
        }

        // Loop through the array of objects and remove duplicates from each timing array
        const newArray = mergedArray.map((obj) => ({
          ...obj,
          timing: removeDuplicateTimingObjects(obj.timing),
        }));

        // ==================
        // REMOVE OVERLAPPING TIME
        // ==================

        function mergeOverlappingTimeRanges(data) {
          data.forEach((day) => {
            const timingArray = day.timing;

            for (let i = 0; i < timingArray.length - 1; i++) {
              for (let j = i + 1; j < timingArray.length; j++) {
                const timing1 = timingArray[i];
                const timing2 = timingArray[j];

                if (
                  timing1.start_time <= timing2.end_time &&
                  timing2.start_time <= timing1.end_time
                ) {
                  // If there's an overlap, merge the two timings
                  timing1.start_time = minTime(
                    timing1.start_time,
                    timing2.start_time
                  );
                  timing1.end_time = maxTime(
                    timing1.end_time,
                    timing2.end_time
                  );

                  // Remove the merged timing from the array
                  timingArray.splice(j, 1);

                  // Restart the inner loop
                  i = -1;
                  break;
                }
              }
            }
          });

          return data;
        }

        function minTime(time1, time2) {
          return time1.localeCompare(time2) < 0 ? time1 : time2;
        }

        function maxTime(time1, time2) {
          return time1.localeCompare(time2) > 0 ? time1 : time2;
        }

        // Call the function to merge overlapping time ranges
        const result = mergeOverlappingTimeRanges(newArray);

        setBusinessSchedule(result);
      }
    }
  }, [allSchedule]);

  // ====== FLOW 2 ENDS =======

  // IF LOADING, SHOW LOADER
  if (loading || !businessSchedule) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (flow === 1) {
    return (
      <div className={styles.days}>
        {businessSchedule.length !== 0 ? (
          businessSchedule.map((i, idx) => (
            <div
              className={`
          ${styles.day}
          ${
            i.timing
              ? i.timing.every((x) => !x.end_time && !x.start_time) &&
                styles.noSlots
              : styles.noSlots
          }
        `}
              key={idx}
            >
              <p className={styles.name}>
                {i.name === "sunday"
                  ? "יום ראשון"
                  : i.name === "monday"
                  ? "יום שני"
                  : i.name === "tuesday"
                  ? "יום שלישי"
                  : i.name === "wednesday"
                  ? "יום רביעי"
                  : i.name === "thursday"
                  ? "יום חמישי"
                  : i.name === "friday"
                  ? "יום שישי"
                  : i.name === "saturday"
                  ? "יום שבת"
                  : ""}
              </p>
              <div className={styles.timings}>
                {i.timing && i.status ? (
                  i.timing.map((j, idxx) => (
                    <div key={idxx} className={styles.time}>
                      {i.timing.every((x) => !x.end_time && !x.start_time) ? (
                        <p style={{ textAlign: "center", width: "100%" }}>
                          סגור
                        </p>
                      ) : !j.end_time && !j.start_time ? null : (
                        <>
                          <img src="/assets/clock.svg" alt="clock" />
                          <p>
                            {j.end_time} - {j.start_time}
                          </p>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: "center", width: "100%" }}>סגור</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noSchedule}>
            <p>עסק זה לא הגדיר שעות וימי קבלה</p>
          </div>
        )}
      </div>
    );
  }

  if (flow === 2) {
    return (
      <div className={styles.days}>
        {businessSchedule.length !== 0 ? (
          businessSchedule.map((i, idx) => (
            <div
              className={`
          ${styles.day}
          ${
            i.timing
              ? i.timing.every((x) => !x.end_time && !x.start_time) &&
                styles.noSlots
              : styles.noSlots
          }
        `}
              key={idx}
            >
              <p className={styles.name}>
                {i.name === "sunday"
                  ? "יום ראשון"
                  : i.name === "monday"
                  ? "יום שני"
                  : i.name === "tuesday"
                  ? "יום שלישי"
                  : i.name === "wednesday"
                  ? "יום רביעי"
                  : i.name === "thursday"
                  ? "יום חמישי"
                  : i.name === "friday"
                  ? "יום שישי"
                  : i.name === "saturday"
                  ? "יום שבת"
                  : ""}
              </p>
              <div className={styles.timings}>
                {i.timing && i.timing.length > 0 ? (
                  i.timing.map((j, idxx) => (
                    <div key={idxx} className={styles.time}>
                      {i.timing.every((x) => !x.end_time && !x.start_time) ? (
                        <p style={{ textAlign: "center", width: "100%" }}>
                          סגור
                        </p>
                      ) : !j.end_time && !j.start_time ? null : (
                        <>
                          <img src="/assets/clock.svg" alt="clock" />
                          <p>
                            {j.end_time} - {j.start_time}
                          </p>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: "center", width: "100%" }}>סגור</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noSchedule}>
            <p>עסק זה לא הגדיר שעות וימי קבלה</p>
          </div>
        )}
      </div>
    );
  }
};
