import { useContext } from "react";
import { ThemeContext } from "../../App";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import ReactCreditCards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import styles from "../../styles/paymentModal.module.scss";
import { format } from "date-fns";

const PaymentModal = ({
  setOpen,
  paymentFormData,
  setPaymentFormData,
  service,
  price,
  dateValue,
  loading,
  handleSubmit,
  paymentErrorMessage,
  setPaymentErrorMessage,
}) => {
  let color = useContext(ThemeContext);

  const textStyle = {
    color: color.mainColor,
  };

  const handleChange = (e) => {
    setPaymentErrorMessage(null);
    setPaymentFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleExpDateChange = (e) => {
    setPaymentErrorMessage(null);
    const input = e.target.value;
    // Remove any non-digit characters
    const formatted = input.replace(/\D/g, "");

    // Check the length to automatically add the "/" after two digits
    if (formatted.length <= 2) {
      setPaymentFormData({
        ...paymentFormData,
        exp: formatted,
      });
    } else {
      setPaymentFormData({
        ...paymentFormData,
        exp: formatted.substring(0, 2) + "/" + formatted.substring(2),
      });
    }
  };

  return (
    <Modal setIsOpen={setOpen}>
      <div className={styles.modalContainer}>
        <div className={styles.modalWrapper}>
          <h2>תשלום מהיר</h2>

          {/* CARD */}
          <div className={styles.formWrapper}>
            <ReactCreditCards
              cvc={paymentFormData.cvv}
              expiry={paymentFormData.exp}
              name={paymentFormData.fullName}
              number={paymentFormData.cardNumber}
            />

            {/* DETAILS */}
            <div className={styles.details}>
              {/* SERVICE NAME */}
              <div className={styles.row}>
                <h4>תשלום עבור:</h4>
                <h4>
                  <span style={textStyle}>{service}</span>
                </h4>
              </div>

              {/* DATE OF APPOINTMENT */}
              <div className={styles.row}>
                <h4>תאריך התור:</h4>
                <h4>
                  <span style={textStyle}>
                    {format(new Date(dateValue), "dd.MM.yyyy")}
                  </span>
                </h4>
              </div>

              {/* AMOUNT TO PAY */}
              <div className={styles.row}>
                <h4>סכום לתשלום:</h4>
                <h4>
                  <span style={textStyle}>₪{price}</span>
                </h4>
              </div>
            </div>

            {/* CARD DETAILS FORM */}
            <h3 className={styles.formHeading}>פרטי כרטיס</h3>
            <form className={styles.cardForm}>
              {/* CARD NUMBER */}
              <div className={styles.formGroup}>
                <label className={styles.label}>מספר כרטיס:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="cardNumber"
                  name="cardNumber"
                  value={paymentFormData.cardNumber}
                  onChange={handleChange}
                />
              </div>

              {/* CVV */}
              <div className={styles.formGroup}>
                <label className={styles.label}>CVV:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="cvv"
                  name="cvv"
                  value={paymentFormData.cvv}
                  onChange={handleChange}
                />
              </div>
              {/* EXPIRY DATE */}
              <div className={styles.formGroup}>
                <label className={styles.label}>תוקף:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="exp"
                  name="exp"
                  placeholder="MM/YY"
                  value={paymentFormData.exp}
                  onChange={handleExpDateChange}
                />
              </div>
              {/* HOLDER ID */}
              <div className={styles.formGroup}>
                <label className={styles.label}>ת.ז</label>
                <input
                  className={styles.input}
                  type="text"
                  id="holderId"
                  name="holderId"
                  value={paymentFormData.holderId}
                  onChange={handleChange}
                />
              </div>

              {/* CARDHOLDER NAME */}
              <div className={styles.formGroup}>
                <label className={styles.label}>שם מלא:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={paymentFormData.fullName}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>

          <div className={styles.formWrapper}>
            <h3 className={styles.formHeading}>פרטי חשבונית</h3>
            <form className={styles.cardForm}>
              {/* COMPANY ID */}
              <div className={styles.formGroup}>
                <label className={styles.label}>תז/ח.פ/ע.מ:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="companyId"
                  name="companyId"
                  value={paymentFormData.companyId}
                  onChange={handleChange}
                />
              </div>
              {/* ADDRESS */}
              <div className={styles.formGroup}>
                <label className={styles.label}>כתובת:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="address"
                  name="address"
                  value={paymentFormData.address}
                  onChange={handleChange}
                />
              </div>
              {/* CITY */}
              <div className={styles.formGroup}>
                <label className={styles.label}>עיר:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="city"
                  name="city"
                  value={paymentFormData.city}
                  onChange={handleChange}
                />
              </div>
              {/* PHONE */}
              <div className={styles.formGroup}>
                <label className={styles.label}>טלפון:</label>
                <input
                  className={styles.input}
                  type="text"
                  id="phone"
                  name="phone"
                  value={paymentFormData.phone}
                  onChange={handleChange}
                />
              </div>
              {/* EMAIL */}
              <div className={styles.formGroup}>
                <label className={styles.label}>
                  כתובת מייל למשלוח חשבונית:
                </label>
                <input
                  className={styles.input}
                  type="email"
                  id="email"
                  name="email"
                  value={paymentFormData.email}
                  onChange={handleChange}
                />
              </div>
            </form>
            {paymentErrorMessage && (
              <small className={styles.errorMessage}>
                {paymentErrorMessage}
              </small>
            )}
            <button
              type="button"
              className={styles.submitBtn}
              style={{ backgroundColor: color.mainColor }}
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "ביצוע תשלום"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
