import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../routes/paths";
import axios from "axios";
import { BASE_URL } from "../config";
import styles from "../styles/business.module.scss";
import Success from "../lottie/success.gif";
import Logo from "../lottie/logowhite.svg";
import stylesDown from "../styles/_schedule/screenThree.module.scss";
import BUSINESS from "../api/services/BUSINESS";

const ConfirmAppointment = () => {
  const { appointmentId } = useParams();

  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [initialFetched, setInitialFetched] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [businessCode, setBusinessCode] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeSlot) => {
    return timeSlot;
  };

  useEffect(() => {
    const initialFetched = async () => {
      setLoading(true);
      try {
        const initialResponse = await axios.post(
          `${BASE_URL}/appointment/confirm`,
          { appointmentId }
        );
        setLoading(false);
        setStatus(initialResponse.data.data.status);
        setAppointmentDetails(initialResponse.data.data);
        setUserId(initialResponse.data.data.result.userId);
        setDate(initialResponse.data.data.result.date);
        setTime(initialResponse.data.data.result.timeSlot);
        setBusinessCode(initialResponse.data.data.result.businessId);
        // console.log("User ID:", initialResponse.data.data.result);
        setInitialFetched(true);
      } catch (error) {
        setInitialFetched(true);
      }
    };

    initialFetched();
  }, []);

  // ======= FETCH BUSINESS DETAILS =======
  useEffect(() => {
    if (businessCode) {
      const fetchData = async () => {
        try {
          const business_details_response = await BUSINESS.details(
            businessCode
          );
          setBusinessName(business_details_response.data.business_name);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [businessCode]);

  const handleConfirmation = async (newStatus) => {
    setError(null);
    try {
      const response = await axios.put(`${BASE_URL}/appointment/confirm/`, {
        appointmentId,
        isConfirmed: newStatus,
      });
      setStatus(newStatus);
    } catch (err) {
      setError("Error confirming appointment: " + err.message);
    }
  };

  const renderContent = () => {
    if (error) {
      return (
        <>
          <header
            style={{
              borderRadius: "5px 5px 0px 0px",
              backgroundColor: "#111827",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <img style={{ margin: "auto" }} width={"100px"} src={Logo} />
          </header>
          <div>
            <h2>קרתה תקלה, יש ליצור קשר עם בעל העסק לשינוי פרטי התור</h2>
            {/* <p>{error}</p> */}
          </div>
        </>
      );
    }

    if (status === "PENDING") {
      return (
        <>
          <header
            style={{
              borderRadius: "5px 5px 0px 0px",
              backgroundColor: "#111827",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <a href="https://tor.digital">
              <img style={{ margin: "auto" }} width={"100px"} src={Logo} />
            </a>
          </header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              gap: "1rem",
              padding: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                borderWidth: "1px",
                borderColor: "black",
                width: "100%",
              }}
            >
              {/* business name */}
              {businessName && (
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {businessName}
                </h4>
              )}
              <h2
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                אנא אשרו את התור
              </h2>

              {/* date */}
              <p>בתאריך: {formatDate(date)}</p>

              {/* time */}
              <p>בשעה: {time}</p>
            </div>

            <p
              style={{
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              אנא אשרו הגעה אל התור
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <button
                style={{
                  backgroundColor: "#2ecc71",
                  padding: "10px 30px",
                  color: "white",
                  fontSize: "22px",
                  borderRadius: "6px",
                }}
                onClick={() => handleConfirmation("CONFIRMED")}
              >
                מגיע
              </button>
              <button
                style={{
                  backgroundColor: "#e74c3c",
                  padding: "10px 30px",
                  color: "white",
                  fontSize: "22px",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  handleConfirmation("DECLINED");
                }}
              >
                {" "}
                לא אגיע
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
                textAlign: "center",
                borderTop: "1px solid #cccccc",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      marginBottom: "20px",
                    }}
                  >
                    שווה להוריד את האפליקציה שלנו!
                  </span>
                  <br />
                  לקביעת תור מהיר וסנכרון ליומן מומלץ להוריד את האפליקציה שלנו
                  ולהתחבר עם הטלפון שלך❤️
                </p>

                <div className={stylesDown.store}>
                  <a
                    id="appclick"
                    href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
                    target="_blank"
                  >
                    <img
                      src="/assets/appstore.svg"
                      alt="זמין להורדה ב-App Store"
                      style={{ width: "55%" }}
                    />
                  </a>

                  <a
                    id="gclick"
                    href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
                    target="_blank"
                  >
                    <img
                      src="/assets/playstore.svg"
                      alt="זמין להורדה ב-Google Play"
                      style={{ width: "55%" }}
                    />
                  </a>
                </div>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
                onClick={() => navigate(paths.my_appointments)}
              >
                יש לך תור קיים? לחצו כאן כדי לצפות בכל התורים שלך {">"}
              </p>
              <a
                style={{
                  textAlign: "center",
                }}
                href="https://join.tor.digital"
              >
                <p className={styles.bottomText}>
                  בעל עסק? לחץ כאן להרשמה מהירה
                </p>
              </a>
            </div>
          </div>
        </>
      );
    }


    if (status === "CONFIRMED") {
      return (
        <>
          <header
            style={{
              borderRadius: "5px 5px 0px 0px",
              backgroundColor: "#111827",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <a href="https://tor.digital">
              <img style={{ margin: "auto" }} width={"100px"} src={Logo} />
            </a>
          </header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              gap: "1rem",
              // height: "80vh",
              marginTop: "30px",
            }}
          >
            {/* business name */}
            {businessName && (
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {businessName}
              </h4>
            )}
            <h2
              style={{
                textAlign: "center",
              }}
            >
              אישרת הגעה לפגישה!
            </h2>
              <h2 style={{textAlign:'center', marginTop:'-20px'}}>נתראה</h2>

            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                borderWidth: "1px",
                borderColor: "black",
                width: "100%",
              }}
            >
              <p>בתאריך: {formatDate(date)}</p>
              <p>בשעה: {time}</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
                textAlign: "center",
              }}
            >
              <img style={{ margin: "auto" }} width={"150px"} src={Success} />
              <p>לכל שינוי יש ליצור קשר ישירות עם בעל העסק</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
                textAlign: "center",
                borderTop: "1px solid #cccccc",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      marginBottom: "20px",
                    }}
                  >
                    שווה להוריד את האפליקציה שלנו!
                  </span>
                  <br />
                  לקביעת תור מהיר וסנכרון ליומן מומלץ להוריד את האפליקציה שלנו
                  ולהתחבר עם הטלפון שלך❤️
                </p>

                <div className={stylesDown.store}>
                  <a
                    id="appclick"
                    href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
                    target="_blank"
                  >
                    <img
                      src="/assets/appstore.svg"
                      alt="זמין להורדה ב-App Store"
                      style={{ width: "55%" }}
                    />
                  </a>

                  <a
                    id="gclick"
                    href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
                    target="_blank"
                  >
                    <img
                      src="/assets/playstore.svg"
                      alt="זמין להורדה ב-Google Play"
                      style={{ width: "55%" }}
                    />
                  </a>
                </div>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
                onClick={() => navigate(paths.my_appointments)}
              >
                יש לך תור קיים? לחצו כאן כדי לצפות בכל התורים שלך {">"}
              </p>
              <a
                style={{
                  textAlign: "center",
                }}
                href="https://join.tor.digital"
              >
                <p className={styles.bottomText}>
                  בעל עסק? לחץ כאן להרשמה מהירה
                </p>
              </a>
            </div>
          </div>
        </>
      );
    }

    if (status === "DECLINED") {
      return (
        <>
          <header
            style={{
              borderRadius: "5px 5px 0px 0px",
              backgroundColor: "#111827",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <a href="https://tor.digital">
              <img style={{ margin: "auto" }} width={"100px"} src={Logo} />
            </a>
          </header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              gap: "1rem",
              // height: "80vh",
              marginTop: "30px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                borderWidth: "1px",
                borderColor: "black",
                width: "100%",
                margin: "auto",
              }}
            >
              <h2
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                אישור תור שנקבע
              </h2>

              <div
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  borderWidth: "1px",
                  borderColor: "black",
                  width: "100%",
                }}
              >
                {/* business name */}
                {businessName && (
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {businessName}
                  </h4>
                )}
                <p>בתאריך: {formatDate(date)}</p>
                <p>בשעה: {time}</p>
                <h1 style={{ fontSize: "56px", padding: "20px" }}>❌</h1>
              </div>
              <p style={{ fontSize: "20px" }}>כבר הגבת לבקשת אישור ההגעה.</p>
              <p style={{ marginTop: "20px", fontSize: "18px" }}>
                לכל שינוי יש ליצור קשר ישירות עם בעל העסק
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
                textAlign: "center",
                borderTop: "1px solid #cccccc",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      marginBottom: "20px",
                    }}
                  >
                    שווה להוריד את האפליקציה שלנו!
                  </span>
                  <br />
                  לקביעת תור מהיר וסנכרון ליומן מומלץ להוריד את האפליקציה שלנו
                  ולהתחבר עם הטלפון שלך❤️
                </p>

                <div className={stylesDown.store}>
                  <a
                    id="appclick"
                    href="https://apps.apple.com/il/app/%D7%AA%D7%95%D7%A8-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C/id1594409312"
                    target="_blank"
                  >
                    <img
                      src="/assets/appstore.svg"
                      alt="זמין להורדה ב-App Store"
                      style={{ width: "55%" }}
                    />
                  </a>

                  <a
                    id="gclick"
                    href="https://play.google.com/store/apps/details?id=com.appointmentbookingapp"
                    target="_blank"
                  >
                    <img
                      src="/assets/playstore.svg"
                      alt="זמין להורדה ב-Google Play"
                      style={{ width: "55%" }}
                    />
                  </a>
                </div>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
                onClick={() => navigate(paths.my_appointments)}
              >
                יש לך תור קיים? לחצו כאן כדי לצפות בכל התורים שלך {">"}
              </p>
              <a
                style={{
                  textAlign: "center",
                }}
                href="https://join.tor.digital"
              >
                <p className={styles.bottomText}>
                  בעל עסק? לחץ כאן להרשמה מהירה
                </p>
              </a>
            </div>
          </div>
        </>
      );
    }
  };

  return <div>{renderContent()}</div>;
};

export default ConfirmAppointment;
