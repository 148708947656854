export const ClockIcon = ({ fill }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7_216)">
        <path
          d="M10.1723 8.82455L8.08086 7.25599V4.06082C8.08086 3.73955 7.82117 3.47986 7.4999 3.47986C7.17863 3.47986 6.91895 3.73955 6.91895 4.06082V7.5465C6.91895 7.72949 7.00493 7.90205 7.15133 8.01126L9.4751 9.75411C9.57966 9.83253 9.70168 9.8703 9.82309 9.8703C10.0003 9.8703 10.1746 9.7907 10.2884 9.63733C10.4814 9.3811 10.4291 9.01685 10.1723 8.82455Z"
          fill={fill}
        />
        <path
          d="M7.5 0C3.36425 0 0 3.36425 0 7.5C0 11.6358 3.36425 15 7.5 15C11.6358 15 15 11.6358 15 7.5C15 3.36425 11.6358 0 7.5 0ZM7.5 13.8381C4.00562 13.8381 1.16188 10.9944 1.16188 7.5C1.16188 4.00562 4.00562 1.16188 7.5 1.16188C10.995 1.16188 13.8381 4.00562 13.8381 7.5C13.8381 10.9944 10.9944 13.8381 7.5 13.8381Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7_216">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
